import React from 'react'
import CreditRatingModal from './components/modals/CreditRatingModal'
import CreditRatingsTable from './components/tables/CreditRatingsTable'

export default class CreditRatings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showCreditRatingModal: false,
    }
  }

  render() {
    return (
      <>
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <CreditRatingsTable title="Credit Ratings" />
          </div>
        </div>
        <CreditRatingModal
          show={this.state.showCreditRatingModal}
          mode={this.state.selectedItem ? 'edit' : 'add'}
          data={this.state.selectedItem}
          onHide={() => {
            this.setState({ showCreditRatingModal: false, selectedItem: null })
          }}
        />
      </>
    )
  }
}
