import React from 'react'

import ResultsTable from './components/tables/ResultsTable'
import AnnualResultsTable from './components/tables/AnnualResultsTable'

import moment from 'moment'

export default class Reports extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showUpcomingResultModal: false,
    }
  }

  _renderReportedResultsTable() {
    return <ResultsTable title={'Reported Results'} />
  }

  _renderAnnualResultsTable() {
    return (
      <div style={{ marginTop: 15 }}>
        <AnnualResultsTable title={'Annual Results'} />
      </div>
    )
  }

  render() {
    return (
      <div className="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" className="container-xxl">
          {this._renderReportedResultsTable()}
          {this._renderAnnualResultsTable()}
        </div>
      </div>
    )
  }
}
