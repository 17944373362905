import React from 'react'

export default class ViewEmailCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: props.email,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    let { email } = this.state
    return (
      <a
        onClick={() => this.props.onClick(email)}
        className="btn btn-icon btn-white btn-sm border-0"
        data-bs-toggle="modal"
        data-bs-target="#view-email"
      >
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
        <span
          className="svg-icon svg-icon-2x svg-icon-primary"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title
          data-bs-original-title="View Email"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
              fill="black"
            />
            <path
              opacity="0.3"
              d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
              fill="black"
            />
          </svg>
        </span>
        {/*end::Svg Icon*/}
      </a>
    )
  }
}
