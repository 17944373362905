import React from "react";

import BaseTable from "./BaseTable";
import YearPicker from "../common/YearPicker";

import moment from "moment";

import { Link } from "react-router-dom";

import FileCell from "./cells/FileCell";
import ReportActionCell from "./cells/ReportActionCell";
import ConfirmModal from "../modals/ConfirmModal";

import PressReleaseModal from "../modals/PressReleaseModal";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import DateTime from "../../../utils/DateTime";

import InvestorActivityTable from "./InvestorActivityTable";

export default class PressReleaseTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      endpoint: props.endpoint,
      selectedDate: moment().format("YYYY"),
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh());
  }

  _deleteReport() {
    let { deleteItem } = this.state;
    Backend.deleteReport({slug:deleteItem})
      .then(() => {
        Notify.success("Post deleted successfully");
        this._refresh();
        this.setState({ deleteItem: null });
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _refresh() {
    this.table.current.refresh();
  }

  _getReportBySlug(slug) {
    this.setState({ isLoading: true });
    Backend.getReportBySlug(slug)
      .then((response) => {
        const selectedItem = {
          id: response.id,
          title: response.title,
          subtitle: response.subtitle,
          slug: response.slug,
          category: response.category,
          published_at: response.published_at,
          is_live: response.is_live,
          featured: response.featured,
          body: response.body,
          initial_slide_number: response.initial_slide_number,
          add_to_homepage_slider: response.add_to_homepage_slider,
        };
        
        if (response.image) {
          selectedItem.image_id = { id: response.image.id };
        }
        
        if (response.file_url) {
          selectedItem.file_url = response.file_url;
        }
        this.setState({
          showPressReleaseModal: true,
          selectedItem,
        });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _getColumns() {
    return [
      {
        Header: "Post Title",
        id: "title",
        sortable: false,
        accessor: (row) => {
          return (
            <div className="d-flex align-items-center">
               {row.image && row.image.url ? (
                <>
                    <div
                    className="c-logo me-5"
                    style={{ backgroundImage: "url(" + row.image.url + ")" }}
                  />
                  <div className="d-flex justify-content-start flex-column">
                    <a href="#" className="text-dark fw-bolder mb-1 fs-6">
                      {row.title}
                    </a>
                  </div>
                </>
              ) : (
                <div className="me-5">
                  <h6 className="text-muted">N/A</h6>
                </div>
            )}
            </div>
          );
        },
        width: 300,
        sortable: false,
      },
      {
        Header: "Date Added",
        id: "created_at",
        sortable: false,
        accessor: (row) => {
          return moment(row.created_at).format("DD MMM YYYY");
        },
        type: "date",
        format: "%d %b %Y",
      },{
        Header: "Published at",
        id: "published_at",
        sortable: false,
        accessor: (row) => {
          if(row.published_at){
            return moment(row.published_at).format("DD MMM YYYY");
          }else{
            return 'N/A'
          }
        },
        type: "date",
        format: "%d %b %Y",
      },
      {
        Header: "Views",
        id: "views",
        sortable: false,
        accessor: "views",
      },
      {
        id: "active",
        Header: "",
        sortable: false,
        width: 100,
        exportable: false,
        Cell: (rowInfo) => {
          return (
            <ReportActionCell
              item={rowInfo.original}
              onDeleteClicked={(item) => {
                this.setState({
                  showDeleteModal: true,
                  deleteItem: item.slug,
                });
              }}
              onEditClicked={(item) => {
                this._getReportBySlug(item.slug);
              }}
            />
          );
        },
      },
    ];
  }

  _renderToolbar() {
    return (
      <div className="card-toolbar">
        <button
          className="btn btn-primary my-5 me-10"
          data-bs-toggle="modal"
          data-bs-target="#add-result"
          onClick={() => {
            this.setState({ showPressReleaseModal: true,selectedItem: null  });
          }}
        >
          <span className="svg-icon btn-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                x="10"
                y="4"
                width="4"
                height="16"
                rx="1"
                fill="black"
                opacity="0.7"
              />
              <rect
                x="4"
                y="10"
                width="16"
                height="4"
                rx="1"
                fill="black"
                opacity="0.7"
              />
            </svg>
          </span>
          <span className="btn-label">Add Press Release</span>
        </button>
        <YearPicker
          activeItem={this.state.selectedDate}
          onItemClicked={(item) => {
            this.setState({ selectedDate: item }, () => this._refresh());
          }}
          maxYears={4}
        />
      </div>
    );
  }

  render() {
    let { selectedDate } = this.state;
    const columns = this._getColumns();

    return (
      <div className="press-release-table">
        <BaseTable
          ref={this.table}
          endpoint={
            selectedDate === "Archive"
              ? window.Api.Reports +
                "?paginate=true&category=News&archive_year=" +
                DateTime.getArchiveYearV2()
              : window.Api.Reports +
                "?paginate=true&category=News&year=" +
                selectedDate
          }
          showFilter={false}
          className="card mb-5 mb-xl-8"
          noDataMessage={"No results found"}
          title={this.props.title}
          renderToolbar={() => this._renderToolbar()}
          columns={columns}
          showExport={false}
          showPagination={true}
          filterForPage="posts"
        />

        <PressReleaseModal
          mode={this.state.selectedItem ? "edit" : "add"}
          data={this.state.selectedItem}
          type={"investor_annual"}
          show={this.state.showPressReleaseModal}
          onHide={() =>
            this.setState({ showPressReleaseModal: false, selectedItem: null })
          }
          onUpdated={() =>
            this.setState({ showPressReleaseModal: false }, () => {
              this._refresh();
            })
          }
          backdrop="static"
        />
        <ConfirmModal
          show={this.state.showDeleteModal}
          title="Delete Post"
          message="Are you sure you want to delete this post?"
          onHide={() => this.setState({ showDeleteModal: false })}
          onConfirm={() => {
            this.setState({ showDeleteModal: false }, () => {
              this._deleteReport();
            });
          }}
        />
      </div>
    );
  }
}

PressReleaseTable.defaultProps = {
  latestResults: false,
};
