export default class Currency {
  static numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  static format(value, currencyCode = 'usd') {
    let dollarValue = value / 100
    let symbol = Currency.getSymbol(currencyCode)
    let price =
      symbol + dollarValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    return Currency.numberWithCommas(price) //.replace(".00", "")
  }

  static getSymbol(currencyCode) {
    switch (currencyCode.toLowerCase()) {
      case 'eur':
        return '€'
      case 'gbp':
        return '£'
      case 'usd':
      case 'cad':
        return '$'
      default:
        return ''
    }
  }
}
