import React from "react"

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'


export default class CustomPhoneInput extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      ...nextProps,
      value: this.state.value
    })
  }

  _handlePhoneUpdate(value, data, e, formattedValue){
    this.setState({
      value: formattedValue,
    })
    if(this.props.onChange){
      this.props.onChange(value, data, e, formattedValue)
    }
  }


  render() {

    return (
      <PhoneInput
        {...this.props}
        value={this.state.value}
        enableLongNumbers={true}
        onChange={(value, data, e, formattedValue) => this._handlePhoneUpdate(value, data, e, formattedValue)}
      />
    )
  }

}
