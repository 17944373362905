import React from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";

import AuthManager from "../utils/AuthManager";
import Email from "../utils/Email";
import Notify from "../utils/Notify";

const MODE_SIGNUP = "signup";
const MODE_SIGNIN = "signin";
const MODE_FORGOT_PASSWORD = "forgot_password";
const MODE_RESET_PASSWORD = "reset_password";
const MODE_MFA = "mfa";

const env = runtimeEnv();

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    var mode =
      window.location.href.indexOf("m=r") > -1
        ? MODE_FORGOT_PASSWORD
        : MODE_SIGNIN;

    this.state = {
      email: "",
      password: "",
      isLoading: false,
      signUpStep: 1,
      mode: MODE_SIGNIN,
    };
  }

  _updateMode(mode) {
    this.setState({
      mode,
      password: "",
      confirm_password: "",
      showPassword: false,
      showConfirmPassword: false,
      isLoading: false,
      error: null,
    });
  }

  _isFormValid() {
    let { email, password, confirm_password, mode, code } = this.state;

    if (!Email.isValid(email)) {
      this.setState({ error: "Please enter a valid email address" });
      return false;
    }

    if (mode == MODE_RESET_PASSWORD) {
      if (!code) {
        this.setState({ error: "Please enter a valid code" });
        return false;
      }
      if (password.length < 6) {
        this.setState({ error: "Password must be at least 6 characters" });
        return false;
      }
      if (password != confirm_password) {
        this.setState({ error: "Passwords do not match" });
        return false;
      }
    }

    return true;
  }

  _handleLogInClicked(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password } = this.state;

    this.setState({ isLoading: true });
    AuthManager.login(email, password, false)
      .then(() => {
        this.setState({ isLoading: false });
        window.location = "/";
      })
      .catch((response) => {
        if (response?.message?.ephemeral_token) {
          this.setState({
            isLoading: false,
            mode: MODE_MFA,
            mfaMethod: response.message.method,
            ephemeralToken: response.message.ephemeral_token,
            lastFourPhoneDigits: response.message.phone_number_last_4_digit,
          });
        } else {
          this.setState({
            isLoading: false,
            error: response.message,
          });
        }
      });
  }

  _handleVerifyLogInClicked(e) {
    e.preventDefault();

    let { ephemeralToken, code } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });
    AuthManager.verifyLogin(ephemeralToken, code)
      .then(() => {
        let url = "/";
        window.location = url;
      })
      .catch((response) => {
        this.setState({
          isLoading: false,
          error: response.message,
        });
      });
  }

  _resendMFACode() {
    const { mfaMethod, ephemeralToken, email } = this.state;

    this.setState({ isLoading: true });

    AuthManager.resendCode(mfaMethod, email, ephemeralToken)
    .then(() => {
      this.setState({ isLoading: false });
    })
    .catch((error) => {
      Notify.error(error.message);
      this.setState({
        isLoading: false,
      });
    });
  }

  _renderMFASubtitle(mfaMethod) {
    let subtitle = "To verify it's you, ";

    if (mfaMethod === "sms_twilio") {
      let lastFourPhoneDigits = this.state.lastFourPhoneDigits
      subtitle += `we've sent a 6-digit code to your phone with a number ending in ${lastFourPhoneDigits}.`;
    } else if (mfaMethod === "sms_api") {
      subtitle += `we've sent a 6-digit code to your mobile.`;
    } else {
      subtitle += "enter a code generated by your authenticator app.";
    }
    return subtitle;
  }

  _handleRequestResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email } = this.state;

    this.setState({ isLoading: true });
    AuthManager.requestResetPassword(email)
      .then(() => {
        this._updateMode(MODE_RESET_PASSWORD);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _handleResetPassword(e) {
    e.preventDefault();
    if (!this._isFormValid()) {
      return;
    }
    let { email, password, code } = this.state;

    this.setState({ isLoading: true });
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this._updateMode(MODE_SIGNIN);
        Notify.success("Your password has been reset");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _renderError() {
    let { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    );
  }

  _renderSignIn() {
    let { email, password, isLoading } = this.state;
    return (
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_sign_in_form"
        action="#"
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Sign In</h1>
        </div>
        {this._renderError()}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Email</label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            placeholder="Email"
            name="email"
            autoComplete="off"
            value={email}
            onChange={(e) =>
              this.setState({ email: e.target.value, error: null })
            }
            autoFocus
          />
        </div>
        <div className="fv-row mb-10">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Password
            </label>
            <a
              onClick={() => this._updateMode(MODE_FORGOT_PASSWORD)}
              class="link-primary fs-6 fw-bolder"
              style={{ cursor: "pointer" }}
            >
              Forgot Password ?
            </a>
          </div>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            placeholder="Password"
            name="password"
            autoComplete="off"
            value={password}
            onChange={(e) =>
              this.setState({ password: e.target.value, error: null })
            }
          />
        </div>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-lg btn-primary w-100 mb-5"
            onClick={(e) => {
              const { response } = this.state;

              const ephemeralToken = response?.message?.ephemeral_token;

              this._handleLogInClicked(e);
              ephemeralToken &&
                this.setState({
                  mode: MODE_MFA,
                  error: null,
                });
            }}
            disabled={isLoading}
          >
            {isLoading ? "Loading... " : "Sign In"}
          </button>
        </div>
      </form>
    );
  }

  _renderForgotPassword() {
    let { email, password, isLoading, showPassword } = this.state;
    return (
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_password_reset_form"
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Forgot Password ?</h1>
          <div className="text-gray-400 fw-bold fs-4">
            Enter your email to reset your password.
          </div>
        </div>
        {this._renderError()}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Email</label>
          <input
            className="form-control form-control-solid"
            type="text"
            placeholder="Email"
            name="email"
            autoComplete="off"
            value={email}
            onChange={(e) =>
              this.setState({ email: e.target.value, error: null })
            }
            autoFocus
          />
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="button"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            disabled={isLoading}
            onClick={(e) => this._handleRequestResetPassword(e)}
          >
            {isLoading ? "Loading... " : "Submit"}
          </button>
          <a
            onClick={() => this._updateMode(MODE_SIGNIN)}
            className="btn btn-lg btn-light-primary fw-bolder"
          >
            Cancel
          </a>
        </div>
      </form>
    );
  }

  _renderResetPassword() {
    let {
      isLoading,
      code,
      showPassword,
      showConfirmPassword,
      confirm_password,
    } = this.state;
    return (
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_password_reset_form"
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Reset Password</h1>
          <div className="text-gray-400 fw-bold fs-4">
            A verification code was sent to your email, please enter it below
            along with your new password.
          </div>
        </div>
        {this._renderError()}
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Password
          </label>
          <div className="position-relative mb-3">
            <input
              className="form-control form-control-lg form-control-solid"
              type={showPassword ? "text" : "password"}
              placeholder
              name="password"
              autoComplete="off"
              onChange={(e) =>
                this.setState({ password: e.target.value, error: null })
              }
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
              data-kt-password-meter-control="visibility"
              onClick={() => this.setState({ showPassword: !showPassword })}
            >
              <i className="bi bi-eye-slash fs-2" />
              <i className="bi bi-eye fs-2 d-none" />
            </span>
          </div>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Confirm Password
          </label>
          <div className="position-relative mb-3">
            <input
              className="form-control form-control-lg form-control-solid"
              type={showConfirmPassword ? "text" : "password"}
              placeholder
              name="password"
              autoComplete="off"
              onChange={(e) =>
                this.setState({ confirm_password: e.target.value, error: null })
              }
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
              data-kt-password-meter-control="visibility"
              onClick={() =>
                this.setState({ showConfirmPassword: !showConfirmPassword })
              }
            >
              <i className="bi bi-eye-slash fs-2" />
              <i className="bi bi-eye fs-2 d-none" />
            </span>
          </div>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Code
          </label>
          <input
            className="form-control form-control-solid"
            type="text"
            placeholder="Code"
            autoComplete="off"
            value={code}
            onChange={(e) =>
              this.setState({ code: e.target.value, error: null })
            }
          />
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="button"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            disabled={isLoading}
            onClick={(e) => this._handleResetPassword(e)}
          >
            {isLoading ? "Loading... " : "Submit"}
          </button>
          <a
            onClick={() => this.setState({ mode: MODE_SIGNIN })}
            className="btn btn-lg btn-light-primary fw-bolder"
          >
            Cancel
          </a>
        </div>
      </form>
    );
  }

  _renderMFA() {
    const { mode, code, isLoading, response, mfaMethod } = this.state;

    return (
      <div
        className="login-form login-forgot"
        style={{
          display: mode === MODE_MFA ? "block" : "none",
        }}
      >
        <form
          className="form"
          noValidate="novalidate"
          id="kt_login_forgot_form"
        >
          <div className="pb-5 pt-lg-0 pt-5">
            <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
              Verify Your Identity
            </h3>
            <p className="text-muted font-weight-bold font-size-h6">
              {this._renderMFASubtitle(mfaMethod)}
            </p>
          </div>

          {this._renderError()}

          <div className="form-group">
            <span className="text-dark-75 ml-1">Code</span>
            <input
              className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="text"
              name="code"
              autoComplete="off"
              value={code}
              placeholder="Code (or Backup Code)"
              onChange={(e) =>
                this.setState({ code: e.target.value, error: null })
              }
            />
            <div>
              {mfaMethod !== "app" && (
                <>
                  <span className="text-muted ml-1 mt-1">
                    Didn't receive a code ?
                    <a
                      href="javascript:;"
                      tabIndex="4"
                      className="text-primary font-weight-bold text-hover-primary pt-5 ml-1"
                      style={{ textDecoration: "underline" }}
                      onClick={() => this._resendMFACode()}
                    >
                      Resend Code
                    </a>
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="form-group d-flex flex-wrap pb-lg-0 pull-right mt-3">
            <button
              className="btn btn btn-lg btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  code: "",
                  mode: MODE_SIGNIN,
                  error: null,
                });
              }}
            >
              Cancel
            </button>

            <button
              className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
              onClick={(e) => {
                this._handleVerifyLogInClicked(e);
              }}
              disabled={isLoading}
            >
              {isLoading ? "Loading... " : "Verify"}
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    let { email, password, confirm_password, isLoading, mode } = this.state;
    return (
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div
            className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative"
            style={{
              background:
                "linear-gradient(0deg, rgba(40,54,255,1) 0%, rgba(8,8,116,1) 100%)",
            }}
          >
            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px h-100">
              <div
                className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20"
                style={{ justifyContent: "center" }}
              >
                <a href="#" className="py-9 mb-5">
                  <img
                    alt="Logo"
                    src="assets/media/logos/avolon-logo.png"
                    className="h-60px ps-10"
                  />
                </a>
                <h1
                  className="fw-bolder fs-2qx pb-5 pb-md-10"
                  style={{ color: "#FFF" }}
                >
                  Avolon Admin Login
                </h1>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div
                className="w-lg-500px p-10 p-lg-15 mx-auto"
                style={{
                  display: "block",
                }}
              >
                {mode === MODE_SIGNIN && this._renderSignIn()}
                {/* <!--begin::MFA--> */}
                {this._renderMFA()}
                {/* <!--end::MFA--> */}
                {mode === MODE_FORGOT_PASSWORD && this._renderForgotPassword()}
                {mode === MODE_RESET_PASSWORD && this._renderResetPassword()}
              </div>
            </div>

            <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
              <div className="d-flex flex-center fw-bold fs-6">
                <a
                  onClick={() => {
                    window.location.href = "https://avolon.aero/about-us";
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-muted text-hover-primary px-2"
                  target="_blank"
                >
                  About
                </a>
                <a
                  onClick={() => {
                    window.location = "https://avolon.aero/contact";
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-muted text-hover-primary px-2"
                  target="_blank"
                >
                  Support
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
