import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'
import FlatPickr from 'react-flatpickr'
import Dropzone from '../common/CustomDropzone'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import TextFormat from '../../../utils/TextFormat'

export default class CreditRatingModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: props.mode,
      data: props.data,
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _updateData(key, value) {
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data })
  }

  _isFormValid() {
    let { data } = this.state

    let error = null

    if (!data.name) {
      error = 'Agency Name is required.'
    }
    if (!data.rating) {
      error = 'Agency Rating is required.'
    }
    if (!data.logo_url) {
      error = 'Agency Logo is required.'
    }
    if (!data.issued_at) {
      error = 'Issue Date is required.'
    }

    if (error) {
      Notify.error(error.message)
      return false
    }

    return true
  }

  _handleSubmit() {
    let { data, file, mode } = this.state

    if (!this._isFormValid()) {
      return
    }
    this.setState({ isLoading: true })
    Backend.submitCreditRating(data, mode)
      .then(() => {
        this.setState({
          isLoading: false,
        })
        Notify.success('Credit rating has been updated successfully.')
        this.props.onUpdated()
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { data, show, isLoading, fileUploading, mode } = this.state

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>
              {TextFormat.capitalizeFirst(mode)} Credit Rating
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form id="add-rating-form" className="form" action="#">
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Agency Name</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="What is the name of the agency submitting the rating"
                  />
                </label>
                <input
                  className="form-control form-control-solid"
                  type="text"
                  placeholder=""
                  name="agency"
                  value={data?.name}
                  onChange={(e) => {
                    this._updateData('name', e.target.value)
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Agency Logo</span>
                </label>
                <Dropzone
                  type="image/*"
                  subtitle="Upload a .JPG or .PNG"
                  multi={false}
                  onUpload={(file) => {
                    this.setState({ fileUploading: false })
                    this._updateData('logo_url', file.url)
                  }}
                  isUploading={(value) =>
                    this.setState({ fileUploading: value })
                  }
                  onRemove={() => {
                    this.setState({ fileUploading: false })
                    delete data.logo_url
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Agency Rating</span>
                </label>

                <input
                  className="form-control form-control-solid"
                  type="text"
                  placeholder=""
                  name="agency_rating"
                  value={data?.rating}
                  onChange={(e) => {
                    this._updateData('rating', e.target.value)
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="required fs-6 fw-bold form-label mb-2">
                  Issue Date
                </label>
                <div className="position-relative">
                  <FlatPickr
                    value={data?.issued_at}
                    className="form-control"
                    options={{
                      enableTime: false,
                      noCalendar: false,
                      altInput: true,
                      dateFormat: 'Y-m-d',
                      static: true,
                    }}
                    onChange={(dates) => {
                      let date = dates[0]
                      this._updateData('issued_at', date)
                    }}
                  />
                  <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                    <span className="svg-icon svg-icon-muted">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={21}
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                          fill="black"
                        />
                        <path
                          d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold form-label mb-2">Outlook</label>
                <select
                  className="form-control form-control-solid"
                  value={data?.outlook}
                  onChange={(e) => {
                    if (e.target.value == '') {
                      this._updateData('outlook', null)
                    } else {
                      this._updateData('outlook', e.target.value)
                    }
                  }}
                >
                  <option value="" selected disabled>
                    Select Outlook
                  </option>
                  <option value="" selected>
                    No Outlook
                  </option>
                  <option value="positive">Positive</option>
                  <option value="stable">Stable</option>
                  <option value="negative">Negative</option>
                </select>
              </div>
              <div className="text-center pt-15">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => this._handleSubmit()}
                  id="upload-success"
                  disabled={fileUploading || isLoading}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  <span className="indicator-label">
                    {isLoading || fileUploading
                      ? 'Please wait...'
                      : mode === 'add'
                      ? 'Add Credit Rating'
                      : 'Update Credit Rating'}
                  </span>
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
