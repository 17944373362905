import React from 'react'
import CustomSvg from '../../common/CustomSvg'
import Backend from '../../../../utils/Backend'
import Notify from '../../../../utils/Notify'
export default class FileCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      url: props.url,
      slug: props.slug,
      fileType: props.fileType,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    let { url, fileType } = this.state
    if (!url) {
      return null
    }
    if (!fileType) {
      fileType = url.split('.').pop()
    }
    if (!url.includes('cloudfront')) {
      fileType = 'link'
    }
    return (
      <div>
        <a
          href={url}
          target="_blank"
          className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
        >
          <div className="symbol symbol-40px me-5">
            <CustomSvg text={fileType} />
          </div>
        </a>
      </div>
    )
  }
}

FileCell.defaultProps = {}
