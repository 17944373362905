import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";
import Dropzone from "../common/CustomDropzone";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

export default class ResubscribeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      show: props.show,
      mode: props.mode,
      uploadCompleted: false,
      unsubscribedUsers: [],
      subscribedCount: 0
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }


  _submitFileInfo(fileId) {
    let data  = {id:fileId};
    this.setState({ isLoading: true });
    Backend.resubscribeInvestors(data)
      .then((response) => {
        if(response.unsuccessful_emails != undefined){
          this.setState({unsubscribedUsers:response.unsuccessful_emails})
        }
        this.setState({
          isLoading: false,
          uploadCompleted: true,
          subscribedCount: response.successful_email_count
        });
        Notify.success("File has been updated successfully.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { show, isLoading, fileUploading,unsubscribedUsers,uploadCompleted,subscribedCount } = this.state;
    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
            this.setState({unsubscribedUsers:[],uploadCompleted:false,subscribedCount:0})
          }
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content w-600px">
          <Modal.Body>
            <form id="edit-result-form" className="form" action="#">
              <div className={uploadCompleted?"d-none":""}>
                <Dropzone
                  type="csv"
                  subtitle="Upload a .CSV"
                  multi={false}
                  onUpload={(file) => {
                    this.setState({ fileUploading: false });
                    if(file.id){
                      this._submitFileInfo(file.id)
                    }else{
                      Notify.error("Something went wrong while uploading file.");
                    }
                  }}
                  isUploading={(value) =>
                    this.setState({ fileUploading: value })
                  }
                  onRemove={() => {
                    this.setState({unsubscribedUsers:[],uploadCompleted:false})
                  }}
                />
                {isLoading || fileUploading ? "Please wait..." : ""}
              </div>
            </form>
            <div className="justify-content-center mt-4 mb-3">
              {uploadCompleted && (
                <>
                  <h3>Resubscribed Emails</h3>
                  {subscribedCount >= 1 &&(
                    <p>The emails in the list have been resubscribed</p>
                  )}
                </>
              )}

              {unsubscribedUsers.length > 0 && (
                <div className="alert custom-alert-danger">The following emails could not be resubscribed:
                  <table>
                    <tbody>
                    {unsubscribedUsers.map((user, index) => (
                      <tr key={index}>
                        <td>{user}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                onClick={() => {
                  if (!isLoading) {
                    this.props.onHide();
                    this.setState({unsubscribedUsers:[],uploadCompleted:false,subscribedCount:0})
                  }
                }}
                className="btn btn-light me-3"
                data-bs-dismiss="modal"
                >
                  Close
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}