import React from 'react'
import PresentationsTable from './components/tables/PresentationsTable'

export default class Presentations extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }
  render() {
    return (
      <>
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <PresentationsTable title={'Avolon Presentations'} />
          </div>
        </div>
      </>
    )
  }
}
