import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'

import moment from 'moment'

const parse = require('html-react-parser')
export default class EmailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: props.mode,
      email: props.email,
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderAttachments() {
    let { email } = this.state
    if (email.attachments.length <= 0) {
      return null
    }
    return email.attachments.map((attachment, index) => {
      let fileName = attachment.file_url.substr(
        attachment.file_url.lastIndexOf('/') + 1
      )
      return (
        <div className="d-flex align-items-center mb-2">
          <span className="svg-icon svg-icon-2x svg-icon-primary me-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                fill="black"
              />
              <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
            </svg>
          </span>
          <a
            href={attachment.file_url}
            target="_blank"
            className="text-gray-800 text-hover-primary"
          >
            {fileName}
          </a>
        </div>
      )
    })
  }

  render() {
    const { email, show, isLoading } = this.state
    if (!email) {
      return null
    }
    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>
              {email?.subject} <br />
              <span className="text-muted fs-6">
                Sent {moment(email.issued_at).format('Do MMM YYYY')}
              </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form id="edit-user-form" className="form" action="#">
              {email && (
                <div className="d-flex flex-column mb-7 fv-row">
                  {parse(email.body)}
                </div>
              )}

              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="">Attachments</span>
                </label>

                {this._renderAttachments()}
              </div>

              <div className="text-center pt-15">
                <button
                  type="button"
                  onClick={() => this.props.onHide()}
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
