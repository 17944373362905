import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'

import Dropzone from '../common/CustomDropzone'
import FlatPickr from 'react-flatpickr'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import General from '../../../utils/General'

export default class OtherFileModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
      show: props.show,
      mode: props.mode,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _updateData(key, value) {
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data })
  }

  _isFormValid() {
    let { data } = this.state

    let error = null
    if (!data.title) {
      error = 'Title is required.'
    }
    if (!data.slug) {
      error = 'Slug is required.'
    }

    if (!data.published_at) {
      error = 'Release Date is required.'
    }

    if (!data.file_url) {
      error = 'Document is required.'
    }

    if (error) {
      Notify.error(error.message)
      return false
    }

    return true
  }

  _updateReport() {
    let { data, mode } = this.state

    if (!this._isFormValid()) {
      return
    }
    data.is_live = true

    data.category = 'investor_others'

    this.setState({ isLoading: true })
    Backend.submitReport(data, mode)
      .then(() => {
        this.setState({
          isLoading: false,
        })
        Notify.success('File has been updated successfully.')
        this.props.onUpdated()
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { data, mode, show, isLoading, fileUploading } = this.state
    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>
              {mode === 'edit' ? 'Edit File' : 'Add File'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="edit-result-form" className="form" action="#">
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="What is the name of the result"
                  />
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  value={data?.title}
                  name="result_name"
                  onChange={(e) => {
                    this._updateData('title', e.target.value)
                  }}
                />
              </div>
              {mode === 'add' && (
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Slug</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder=""
                    value={data?.slug}
                    name="result_slug"
                    onChange={(e) => {
                      this._updateData('slug', e.target.value)
                    }}
                  />
                </div>
              )}
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="required fs-6 fw-bold form-label mb-2">
                  Release Date
                </label>
                <FlatPickr
                  value={data?.published_at}
                  className="form-control"
                  options={{
                    enableTime: false,
                    noCalendar: false,
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    static: true,
                  }}
                  onChange={(dates) => {
                    let date = dates[0]
                    this._updateData('published_at', date)
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold form-label mb-2">
                  Upload Document
                </label>
                <Dropzone
                  type="application/pdf, application/msword"
                  subtitle="Upload a .PDF or .DOC"
                  multi={false}
                  onUpload={(file) => {
                    this.setState({ fileUploading: false })
                    this._updateData('file_url', file.url)
                  }}
                  isUploading={(value) =>
                    this.setState({ fileUploading: value })
                  }
                  onRemove={() => {
                    this.setState({ fileUploading: false })
                    delete data.file_url
                  }}
                />
              </div>

              <div className="text-center pt-15">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  id="edit-success"
                  className="btn btn-primary"
                  disabled={isLoading || fileUploading}
                  data-bs-dismiss="modal"
                  onClick={() => this._updateReport()}
                >
                  {isLoading || fileUploading
                    ? 'Please wait...'
                    : 'Save Changes'}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
