import React from 'react'

export default class CustomSvg extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      text: props.text,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    return (
      <svg
        width={80}
        height={50}
        viewBox="0 0 330 417"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="15.3501" width={314} height={417} rx={20} fill="#DEDEDE" />
        <rect
          x="25.3501"
          y={308}
          width={293}
          height={95}
          rx={20}
          fill="#0B27B9"
        />
        <mask
          id="mask0_302_2"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x={22}
          y={86}
          width={122}
          height={90}
        >
          <path
            d="M38.7064 175.584L142.339 161.116C145.387 147.054 145.662 114.232 122.376 95.4352C93.2693 71.9398 41.0513 101.559 26.8589 125.695C15.505 145.005 30.0264 167 38.7064 175.584Z"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask0_302_2)">
          <ellipse
            cx="93.0173"
            cy="173.192"
            rx={55}
            ry="44.5"
            transform="rotate(15.4557 93.0173 173.192)"
            fill="#68CD4D"
          />
        </g>
        <path
          d="M199.35 234.5C177.35 157.3 132.183 125 112.35 118.5L306.35 101L199.35 234.5Z"
          fill="#68CD4D"
        />
        <text
          style={{
            textTransform: 'uppercase',
            fontFamily: 'arial',
            fontSize: '80px',
          }}
          x="52%"
          y="92%"
          dominantbaseline="middle"
          textAnchor="middle"
          fill="white"
        >
          {this.props.text}
        </text>
      </svg>
    )
  }
}
