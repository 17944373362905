import React from "react";

import AsyncStorage from "./AsyncStorage";
import FetchHelper from "./FetchHelper";

import jwtDecode from "jwt-decode";
import moment from "moment";

var isLoggedIn = false;
var accessToken = null;
var refreshToken = null;
var currentUser = null;
var currentWebsite = null;

const KEY_ACCESS_TOKEN = "accessToken";
const KEY_REFRESH_TOKEN = "refreshToken";

export const KEY_WEBSITE = "website";

export default class AuthManager {
  static isAuthenticated() {
    return AuthManager.isLoggedIn;
  }

  static getAccessToken() {
    return AuthManager.accessToken;
  }

  static getCurrentUser() {
    return AuthManager.currentUser;
  }

  static isAdmin() {
    return AuthManager.currentUser.role === "admin";
  }

  static _hasError(responseJson) {
    let hasError = false;
    let token = responseJson.token;

    if (!token) {
      hasError = true;
    }

    return hasError;
  }

  static register(data) {
    return FetchHelper.post(window.Api.Register, data, false, false)
      .then((responseJson) => {
        if (this._hasError(responseJson)) {
          throw AuthManager.getError(responseJson);
        }
        AuthManager._updateToken(responseJson.token);
        AuthManager._setUser(responseJson, false);
        return responseJson;
      })
      .catch((error) => {
        throw AuthManager.getError(error);
      });
  }

  static login(email, password) {
    let data = { email, password };

    return FetchHelper.post(window.Api.User.Login, data, false, false)
      .then((responseJson) => {
        return AuthManager._handleLoginResponse(responseJson);
      })
      .catch((error) => {
        throw AuthManager.getError(error);
      });
  }

  static verifyLogin(ephemeral_token, code) {
    let data = { ephemeral_token, code };

    return FetchHelper.post(`${window.Api.User.Login}/code`, data, false, false)
      .then((responseJson) => {
        return AuthManager._handleLoginResponse(responseJson);
      })
      .catch((error) => {
        throw AuthManager.getError(error);
      });
  }

  static _handleLoginResponse(responseJson, forceWebsite = true) {
    return new Promise((resolve, reject) => {
      if (responseJson.ephemeral_token) {
        throw responseJson;
      }

      if (this._hasError(responseJson)) {
        throw AuthManager.getError(responseJson);
      }
      if (!responseJson.admin) {
        throw {
          error: "invalid user",
          message: "Only admin accounts can access this",
        };
      }
      AuthManager._updateToken(responseJson.token);
      AuthManager._setUser(responseJson, forceWebsite);

      return resolve(responseJson);
    });
  }

  // static login(email, password, forceWebsite = true) {
  //   let data = { email, password }
  //   return FetchHelper.post(window.Api.User.Login, data, false, false)
  //     .then((responseJson) => {
  //       if (this._hasError(responseJson)) {
  //         throw AuthManager.getError(responseJson)
  //       }
  //       if (!responseJson.admin) {
  //         throw {
  //           error: 'invalid user',
  //           message: 'Only admin accounts can access this',
  //         }
  //       }

  //       AuthManager._updateToken(responseJson.token)
  //       AuthManager._setUser(responseJson, forceWebsite)

  //       return responseJson
  //     })
  //     .catch((error) => {
  //       throw AuthManager.getError(error)
  //     })
  // }

  static _getMinutesUntilTokenExpiration() {
    var decodedJWT = jwtDecode(AuthManager.accessToken);
    var exp = decodedJWT.exp * 1000;
    var expirationTime = moment(exp);
    var today = moment();
    let absoluteDifference = Math.abs(expirationTime.diff(today, "minutes"));
    return absoluteDifference;
  }

  static async validateTokens(onSuccess, onError) {
    return AuthManager.refreshTokens()
      .then(() => {
        return onSuccess();
      })
      .catch((error) => {
        onError();
        // Token has expired.
        window.location.href = "/login";
      });
  }

  static refreshTokens() {
    return AsyncStorage.getItem(KEY_ACCESS_TOKEN)
      .then((token) => {
        if (!token) {
          throw { message: "No Token Found" };
          return;
        }
        // try and refresh the token if we find it, if this fails
        // our token has expired and we will need user to re login
        // manually
        const data = { token: token };
        return FetchHelper.post(
          window.Api.User.RefreshToken,
          data,
          false,
          false
        );
      })
      .then((tokenResponse) => {
        return AuthManager._updateToken(tokenResponse.token);
      });
  }

  static async hashLogin() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const hash = params.get("h");
    if (!hash) {
      return false;
    }

    window.history.replaceState(null, "", window.location.pathname);

    return FetchHelper.post(
      `${window.Api.Base}/hashes/login`,
      { hash },
      false,
      false
    ).then((tokens) => {
      AuthManager._updateTokens(tokens);
      return true;
    });
  }

  static silentLogin() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const hash = params.get("h");
    if (!hash) {
      return AuthManager._silentLogin();
    }

    window.history.replaceState(null, "", window.location.pathname);

    return FetchHelper.post(
      `${window.Api.Base}/hashes/login`,
      { hash },
      false,
      false
    )
      .then((tokens) => {
        AuthManager._updateTokens(tokens);
        return AuthManager._silentLogin();
      })
      .catch((error) => {
        return AuthManager._silentLogin();
      });
  }

  static _silentLogin() {
    return AuthManager.refreshTokens()
      .then(() => {
        return FetchHelper.get(window.Api.User.Info);
      })
      .then((responseJson) => {
        AuthManager._setUser(responseJson);
        return AuthManager.currentUser;
      })
      .catch((error) => {
        AuthManager.accessToken = null;
        AuthManager.refreshToken = null;
        throw error;
      });
  }

  static async logout() {
    AuthManager.removeCredentials();
    window.location.href = "/login";
  }

  static requestResetPassword(email) {
    return FetchHelper.post(
      window.Api.User.RequestResetPassword,
      {
        email,
      },
      false,
      false
    );
  }

  static resetPassword(email, password, code) {
    let data = {
      email,
      password,
      verification_code: code,
    };
    return FetchHelper.post(window.Api.User.ResetPassword, data, false, false);
  }

  static _updateTokens(tokens) {
    AuthManager.accessToken = tokens.access;
    AuthManager.refreshToken = tokens.refresh;
    AsyncStorage.setItem(KEY_ACCESS_TOKEN, AuthManager.accessToken);
    AsyncStorage.setItem(KEY_REFRESH_TOKEN, AuthManager.refreshToken);
  }

  static removeCredentials() {
    AuthManager.accessToken = null;
    AuthManager.refreshToken = null;
    AuthManager.isLoggedIn = false;
    AuthManager.currentUser = null;
    AuthManager.currentWebsite = null;
    AsyncStorage.removeItem(KEY_WEBSITE);
    AsyncStorage.removeItem(KEY_ACCESS_TOKEN);
    return AsyncStorage.removeItem(KEY_REFRESH_TOKEN);
  }

  static getError(error) {
    var errorMessage = "An unexpected error occured";
    if (error.email) {
      errorMessage = error.email[0];
    } else if (error.message) {
      errorMessage = error.message;
    } else if (error.non_field_errors) {
      errorMessage = error.non_field_errors[0];
    } else if (error.detail) {
      errorMessage = error.detail;
    } else if (error.ephemeral_token) {
      errorMessage = error;
    }
    return { error: errorMessage, message: errorMessage };
  }

  static _updateToken(token) {
    AuthManager.accessToken = token;
    AsyncStorage.setItem(KEY_ACCESS_TOKEN, token);
  }

  static _setUser(responseJson) {
    AuthManager.isLoggedIn = true;
    if (responseJson.admin) {
      AuthManager.currentUser = responseJson.admin;
      AuthManager.userType = "admin";
    } else {
      throw {
        detail: "Only admin can access this platform",
      };
    }
  }

  static _getCurrentUser(responseJson) {
    if (responseJson.admin) {
      return responseJson.admin;
    }
    return null;
  }

  static getHeaders(contentType = "application/json", addTokens = true) {
    var headers = {};

    if (contentType === "application/json" && addTokens) {
      headers = { "Content-Type": contentType };
    }
    if (AuthManager.accessToken && addTokens) {
      headers["Authorization"] = "Bearer " + AuthManager.accessToken;
    }
    return new Headers(headers);
  }

  /*** Multi Factor Authentication ***/
  static getActiveMethods() {
    return FetchHelper.get(`${window.Api.MFA}/active-methods`);
  }

  static activateMethod(method) {
    return FetchHelper.post(`${window.Api.MFA}/${method}/activate`);
  }

  static activateCode(method, code) {
    let data = {
      code,
    };

    return FetchHelper.post(
      `${window.Api.MFA}/${method}/activate/confirm`,
      data
    ).then((response) => response.backup_codes);
  }

  static resendCode(method, email, ephemeral_token) {
    let data = { method, email, ephemeral_token };
    return FetchHelper.post(
      `${window.Api.MFA}/code/resend`,
      data,
      false,
      false
    );
  }

  static requestCode(method) {
    let data = { method };
    return FetchHelper.post(`${window.Api.MFA}/code/request`, data);
  }

  static deactivateCode(method, code) {
    let data = {
      code,
    };

    return FetchHelper.post(`${window.Api.MFA}/${method}/deactivate`, data);
  }

  static regenerateBackupCodes(code) {
    let data = {
      code,
    };

    return FetchHelper.post(
      `${window.Api.MFA}/backup-codes/regenerate`,
      data
    ).then((response) => response.backup_codes);
  }

  static setDefaultMethod(method, code) {
    let data = { method, code };
    return FetchHelper.post(`${window.Api.MFA}/change-primary-method`, data);
  }
}
