import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import TextFormat from "../../../utils/TextFormat";

import PhoneInput from "../common/PhoneInput";

export default class AdminUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: props.mode,
      data: props.data,
      show: props.show,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _updateData(key, value) {
    let data = { ...this.state.data };
    data[key] = value;
    this.setState({ data });
  }


  _isFormValid() {
    let { data, mode } = this.state;

    let error = null;

    if (!data.first_name) {
      error = "First name is required.";
    }
    if (!data.last_name) {
      error = "Last name is required.";
    }
    if (!data.email) {
      error = "Email is required.";
    }
    if (!data.password && mode === "add") {
      error = "Password is required.";
    }
    if (error) {
      Notify.error(error.message);
      return false;
    }

    return true;
  }

  _submitUser() {
    let { data, mode } = this.state;
    if (!this._isFormValid()) {
      return;
    }
    this.setState({ isLoading: true });
    Backend.updateAdminUser(data, mode)
      .then(() => {
        this.setState({
          isLoading: false,
        });
        Notify.success("Admin has been updated successfully.");
        this.props.onUpdated();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { data, show, isLoading, mode } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>{TextFormat.capitalizeFirst(mode)} User</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form id="kt_modal_add_user_form" className="form" action="#">
              <div
                className="d-flex flex-column scroll-y me-n7 pe-7"
                id="kt_modal_add_user_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_add_user_header"
                data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                data-kt-scroll-offset="300px"
                style={{ maxHeight: "374px" }}
              >
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="First name"
                    value={data?.first_name}
                    onChange={(e) =>
                      this._updateData("first_name", e.target.value)
                    }
                  />
                </div>
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Last name"
                    value={data?.last_name}
                    onChange={(e) =>
                      this._updateData("last_name", e.target.value)
                    }
                  />
                </div>
                {mode === "add" && (
                  <div className="fv-row mb-7">
                    <label className="fw-bold fs-6 mb-2">
                      Phone Number
                    </label>
                    <div className="form-control form-control-solid">
                    <PhoneInput
                      value={"+353"}
                      onChange={(value, phoneData) => {

                        data.phone_country_code = `+${phoneData.dialCode}`;
                        data.phone_number = value.slice(phoneData.dialCode.length);

                        this.setState({
                          data,
                        });
                      }}
                      inputProps={{
                        autocomplete: "off",
                        className: "form-control form-control-solid phone-input-styles",
                      }}
                    />
                    </div>
                  </div>
                )}
                {mode === "add" && (
                  <div className="fv-row mb-7">
                    <label className="required fw-bold fs-6 mb-2">Email</label>
                    <input
                      type="email"
                      name="user_email"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      placeholder="example@domain.com"
                      value={data?.email}
                      onChange={(e) =>
                        this._updateData("email", e.target.value)
                      }
                    />
                  </div>
                )}
                {mode === "add" && (
                  <div className="fv-row mb-7">
                    <label className="required fw-bold fs-6 mb-2">
                      Password
                    </label>
                    <input
                      type="password"
                      name="user_pass"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      value={data?.password}
                      onChange={(e) =>
                        this._updateData("password", e.target.value)
                      }
                    />
                  </div>
                )}
              </div>
              <div className="text-center pt-15">
                <button
                  type="button"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={() => this.props.onHide()}
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  onDisabled={isLoading}
                  onClick={() => this._submitUser()}
                >
                  {isLoading ? "Please wait..." : "Submit"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}
