import React, { Spinner } from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import InputModal from '../modals/InputModal'

export default class TextEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text || '',
      showImageUrlModal: false,
    }
  }

  _onEditorStateChange(text) {
    this.setState({
      text,
    })
    this.props.onUpdated(text)
  }

  modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: () => {
          this.setState({ showImageUrlModal: true })
        },
      },
      clipboard: {
        matchVisual: true,
      },
    },
  }

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'size',
    'font',
  ]

  _addImageUrl(url) {
    var range = this.quillRef.getEditor().getSelection()
    if (url) {
      this.quillRef.getEditor().insertEmbed(range.index, 'image', url, 'user')
    }
  }

  render() {
    return (
      <>
        <ReactQuill
          ref={(el) => (this.quillRef = el)}
          theme="snow"
          defaultValue=""
          modules={this.modules}
          formats={this.formats}
          placeholder={'Enter text here...'}
          value={this.state.text}
          onChange={(text) => this._onEditorStateChange(text)}
        />
        <InputModal
          show={this.state.showImageUrlModal}
          title="Please enter the image URL"
          onHide={() => this.setState({ showImageUrlModal: false })}
          label="Image URL"
          onConfirm={(value) => {
            this.setState({ showImageUrlModal: false }, () => {
              setTimeout(() => {
                this._addImageUrl(value)
              }, 500)
            })
          }}
        />
      </>
    )
  }
}
