import React from 'react'

import BaseTable from './BaseTable'

import moment from 'moment'

import { Link } from 'react-router-dom'

import EditCell from './cells/EditCell'

import CreditRatingModal from '../modals/CreditRatingModal'
import ReorderModal from '../modals/ReorderModal'

import TextFormat from '../../../utils/TextFormat'
import Backend from '../../../utils/Backend'

export default class CreditRatingsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh())
  }

  _refresh() {
    this.table.current.refresh()
  }

  _updateOrder(creditRatings) {
    let data = {
      credit_ratings: creditRatings,
    }
    Backend.updateCreditRatings(data)
      .then((options) => {
        this._refresh()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  _getColumns() {
    return [
      {
        Header: 'Agency',
        id: 'name',
        accessor: (row) => {
          return (
            <div className="d-flex align-items-center">
              <div
                className="c-logo me-5"
                style={{ backgroundImage: 'url(' + row.logo_url + ')' }}
              />
              <div className="d-flex justify-content-start flex-column">
                <a href="#" className="text-dark fw-bolder mb-1 fs-6">
                  {row.name}
                </a>
              </div>
            </div>
          )
        },
        width: 300,
        sortable: false,
      },
      {
        Header: 'Rating',
        id: 'rating',
        accessor: 'rating',
        sortable: false,
      },
      {
        Header: 'Issued',
        id: 'issued_at',
        accessor: (row) => moment(row.issued_at).format('DD MMM YYYY'),
        type: 'date',
        format: '%d %b %Y',
        sortable: false,
      },
      {
        Header: 'Outlook',
        id: 'outlook',
        accessor: (row) => {
          if (!row.outlook) {
            return '-'
          }
          return (
            <span class="badge badge-secondary fs-7 fw-bold w-150px">
              {TextFormat.capitalizeFirst(row.outlook)}
            </span>
          )
        },
        sortable: false,
      },
      {
        id: 'active',
        Header: '',
        sortable: false,
        width: 100,
        exportable: false,
        Cell: (rowInfo) => {
          return (
            <EditCell
              item={rowInfo.original}
              onClick={() => {
                this.setState({
                  showCreditRatingModal: true,
                  selectedItem: rowInfo.original,
                })
              }}
            />
          )
        },
      },
    ]
  }

  _renderToolBar() {
    return (
      <div className="card-toolbar">
        <button
          onClick={() => {
            this.setState({ showReorderModal: true })
          }}
          className="btn btn-light-primary font-weight-bolder my-5 me-5"
          data-bs-toggle="modal"
        >
          <span className="btn-label">Reorder</span>
        </button>
        <button
          onClick={() => {
            this.setState({ showCreditRatingModal: true })
          }}
          className="btn btn-primary my-5"
          data-bs-toggle="modal"
          data-bs-target="#add-rating"
        >
          <span className="btn-label">Add Credit Rating</span>

          <span className="svg-icon btn-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M14 18V16H10V18L9 20H15L14 18Z" fill="black" />
              <path
                opacity="0.3"
                d="M20 4H17V3C17 2.4 16.6 2 16 2H8C7.4 2 7 2.4 7 3V4H4C3.4 4 3 4.4 3 5V9C3 11.2 4.8 13 7 13C8.2 14.2 8.8 14.8 10 16H14C15.2 14.8 15.8 14.2 17 13C19.2 13 21 11.2 21 9V5C21 4.4 20.6 4 20 4ZM5 9V6H7V11C5.9 11 5 10.1 5 9ZM19 9C19 10.1 18.1 11 17 11V6H19V9ZM17 21V22H7V21C7 20.4 7.4 20 8 20H16C16.6 20 17 20.4 17 21ZM10 9C9.4 9 9 8.6 9 8V5C9 4.4 9.4 4 10 4C10.6 4 11 4.4 11 5V8C11 8.6 10.6 9 10 9ZM10 13C9.4 13 9 12.6 9 12V11C9 10.4 9.4 10 10 10C10.6 10 11 10.4 11 11V12C11 12.6 10.6 13 10 13Z"
                fill="black"
              />
            </svg>
          </span>
        </button>
      </div>
    )
  }

  render() {
    let {} = this.state
    const columns = this._getColumns()

    return (
      <div className="credit-ratings-table">
        <BaseTable
          ref={this.table}
          endpoint={window.Api.CreditRatings}
          showFilter={false}
          noDataMessage={'No results found'}
          title={this.props.title}
          columns={columns}
          showExport={false}
          showPagination={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
          renderToolbar={() => this._renderToolBar()}
        />
        <CreditRatingModal
          show={this.state.showCreditRatingModal}
          mode={this.state.selectedItem ? 'edit' : 'add'}
          data={this.state.selectedItem}
          onHide={() =>
            this.setState({ showCreditRatingModal: false, selectedItem: null })
          }
          onUpdated={() => {
            this.setState({ showCreditRatingModal: false })
            this._refresh()
          }}
        />
        <ReorderModal
          title="Reorder Credit Ratings"
          show={this.state.showReorderModal}
          options={this.state.options}
          onHide={() => this.setState({ showReorderModal: false })}
          onUpdate={(data) => {
            this.setState({ showReorderModal: false })
            this._updateOrder(data)
          }}
        />
      </div>
    )
  }
}

CreditRatingsTable.defaultProps = {
  latestResults: false,
}
