import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'
import FlatPickr from 'react-flatpickr'
import Dropzone from '../common/CustomDropzone'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import TextFormat from '../../../utils/TextFormat'
import General from '../../../utils/General'
export default class FileModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: props.mode,
      data: props.data,
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _updateData(key, value) {
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data })
  }

  _isFormValid() {
    let { data } = this.state

    let error = null

    if (!data.category) {
      error = 'File Type is required.'
    }
    if (!data.title) {
      error = 'File Title is required.'
    }
    if (!data.slug) {
      error = 'Slug is required.'
    }
    if (!data.published_at) {
      error = 'Upload Date is required.'
    }
    if (!data.file_url) {
      error = 'File is required.'
    }

    if (error) {
      Notify.error(error.message)
      return false
    }

    return true
  }

  _uploadFile() {
    let { data, file } = this.state
    if (!this._isFormValid()) {
      return
    }
    data.is_live = true

    this.setState({ isLoading: true })
    Backend.submitFile(data)
      .then(() => {
        this.setState({
          isLoading: false,
        })
        Notify.success('File uploaded successfully.')
        this.props.onUpdated()
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { data, show, isLoading, fileUploading, mode } = this.state

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>
              {TextFormat.capitalizeFirst(this.state.mode)} File
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="modal-body scroll-y">
              <form id="add-file-form" className="form" action="#">
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">File Type</span>
                  </label>
                  <div className="row">
                    <div className="col-lg-12">
                      <input
                        type="radio"
                        className="btn-check"
                        name="file_type"
                        defaultValue="document-form"
                        checked={data?.category === 'offering_memoranda'}
                        id="file-type-document"
                        onChange={() => {
                          this._updateData('category', 'offering_memoranda')
                        }}
                      />
                      <label
                        className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-3"
                        htmlFor="file-type-document"
                      >
                        <span className="d-block fw-bold text-start">
                          <span className="text-dark fw-bolder d-block fs-4 mb-2">
                            Offering Memoranda
                          </span>
                        </span>
                      </label>
                      <div className="fv-plugins-message-container invalid-feedback" />
                    </div>
                    <div className="col-lg-12">
                      <input
                        type="radio"
                        className="btn-check"
                        name="file_type"
                        checked={data?.category === 'senior_unsecured_notes'}
                        id="file-type-video"
                        onChange={() => {
                          this._updateData('category', 'senior_unsecured_notes')
                        }}
                      />
                      <label
                        className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                        htmlFor="file-type-video"
                      >
                        <span className="d-block fw-bold text-start">
                          <span className="text-dark fw-bolder d-block fs-4 mb-2">
                            Ammendments to Existing Senior Unsecured Notes
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div id="document-form" className="form-type">
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                      <span className="required">File Title</span>
                      <i
                        className="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        title
                        data-bs-original-title="What is the name of the file"
                        aria-label="What is the name of the file"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder=""
                      value={data?.title}
                      name="result_name"
                      onChange={(e) => {
                        this._updateData('title', e.target.value)
                      }}
                    />
                  </div>
                  {mode == 'add' && (
                    <div className="d-flex flex-column mb-7 fv-row">
                      <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                        <span className="required">Slug</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder=""
                        value={data?.slug}
                        name="result_slug"
                        onChange={(e) => {
                          this._updateData('slug', e.target.value)
                        }}
                      />
                    </div>
                  )}
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="required fs-6 fw-bold form-label mb-2">
                      Upload Date
                    </label>
                    <div className="position-relative">
                      <FlatPickr
                        value={data?.published_at}
                        className="form-control"
                        options={{
                          enableTime: false,
                          noCalendar: false,
                          altInput: true,
                          dateFormat: 'Y-m-d',
                          static: true,
                        }}
                        onChange={(dates) => {
                          let date = dates[0]
                          this._updateData('published_at', date)
                        }}
                      />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                        <span className="svg-icon svg-icon-muted">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={21}
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                              fill="black"
                            />
                            <path
                              d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="required fs-6 fw-bold form-label mb-2">
                      File Upload
                    </label>
                    <Dropzone
                      type="application/pdf,application/msword"
                      subtitle="Upload a .PDF or .Doc"
                      multi={false}
                      onUpload={(file) => {
                        this.setState({ fileUploading: false })
                        this._updateData('file_url', file.url)
                      }}
                      isUploading={(value) =>
                        this.setState({ fileUploading: value })
                      }
                      onRemove={() => {
                        this.setState({ fileUploading: false })
                        delete data.file_url
                      }}
                    />
                  </div>
                </div>
                <div className="text-center pt-15">
                  <button
                    type="button"
                    onClick={() => {
                      this.props.onHide()
                    }}
                    className="btn btn-light me-3"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    id="upload-success"
                    className="btn btn-primary"
                    disabled={this.state.isLoading || this.state.fileUploading}
                    data-bs-dismiss="modal"
                    onClick={() => {
                      this._uploadFile()
                    }}
                  >
                    {isLoading || fileUploading
                      ? 'Please wait...'
                      : 'Save Changes'}
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
