import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'

import Dropzone from '../common/CustomDropzone'
import FlatPickr from 'react-flatpickr'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Notify from '../../../utils/Notify'

import moment from 'moment'

export default class SustainabilityModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
      mode: props.mode,
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _updateData(key, value) {
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data })
  }

  _isFormValid() {
    let { data } = this.state

    let error = null
    if (!data.title) {
      error = 'Title is required.'
    }
    if (!data.slug) {
      error = 'Slug is required.'
    }
    if (!data.subtitle) {
      error = 'Subtitle is required.'
    }
    if (!data.published_at) {
      error = 'Upload Date is required.'
    }
    if (!data.file_url) {
      error = 'Sustainability Report Upload is required.'
    }

    if (error) {
      Notify.error(error.message)
      return false
    }

    return true
  }

  _submitReport() {
    let { data, mode } = this.state
    if (!this._isFormValid()) {
      return
    }
    this.setState({ isLoading: true })
    if (data.video_link) {
      data.video_url = data.video_link
    }
    data.is_live = true
    data.category = 'investor_sustainability'
    Backend.updateSustainabilityReport(data, mode)
      .then(() => {
        this.setState({
          isLoading: false,
        })
        Notify.success('Report has been updated successfully.')
        this.props.onUpdated()
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { data, show, mode, isLoading, fileUploading } = this.state

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>
              {mode === 'edit' ? 'Edit Report' : 'Add Report'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body scroll-y">
              <form id="add-sustainability-form" className="form" action="#">
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="fs-6 fw-bold form-label mb-2">
                    Report Image
                  </label>
                  <Dropzone
                    type="image/*"
                    subtitle="Upload a .JPG or .PNG"
                    multi={false}
                    onUpload={(file) => {
                      this.setState({ fileUploading: false })
                      this._updateData('image', file)
                    }}
                    isUploading={(value) =>
                      this.setState({ fileUploading: value })
                    }
                    onRemove={() => {
                      this.setState({ fileUploading: false })
                      if (data?.image) {
                        delete data?.image
                      }
                    }}
                  />
                </div>
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Report Title</span>
                    <i
                      className="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      title
                      data-bs-original-title="What is the name of the report"
                      aria-label="What is the name of the report"
                    />
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    value={data?.title}
                    name="title"
                    onChange={(e) => this._updateData('title', e.target.value)}
                  />
                </div>
                {mode == 'add' && (
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                      <span className="required">Slug</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder=""
                      value={data?.slug}
                      name="result_slug"
                      onChange={(e) => {
                        this._updateData('slug', e.target.value)
                      }}
                    />
                  </div>
                )}
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Report Subtitle</span>
                    <i
                      className="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      title
                      data-bs-original-title="Describe the content of the report"
                      aria-label="Describe the content of the report"
                    />
                  </label>
                  <textarea
                    className="form-control form-control-solid"
                    placeholder
                    name="sustainability_desc"
                    value={data?.subtitle}
                    onChange={(e) =>
                      this._updateData('subtitle', e.target.value)
                    }
                  />
                </div>
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="required fs-6 fw-bold form-label mb-2">
                    Upload Date
                  </label>
                  <div className="position-relative">
                    <FlatPickr
                      value={data?.published_at}
                      className="form-control"
                      options={{
                        enableTime: false,
                        noCalendar: false,
                        altInput: true,
                        dateFormat: 'Y-m-d',
                        static: true,
                      }}
                      onChange={(dates) => {
                        let date = dates[0]
                        this._updateData('published_at', date)
                      }}
                    />
                    {/*end::Input*/}
                    {/*begin::Card logos*/}
                    <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                      <span className="svg-icon svg-icon-muted">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={21}
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                            fill="black"
                          />
                          <path
                            d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="required fs-6 fw-bold form-label mb-2">
                    Accounting Date
                  </label>
                  <FlatPickr
                    value={data?.accounting_at}
                    className="form-control"
                    options={{
                      enableTime: false,
                      noCalendar: false,
                      altInput: true,
                      dateFormat: 'Y-m-d',
                      static: true,
                    }}
                    onChange={(dates) => {
                      let date = moment(dates[0]).format('YYYY-MM-DD')
                      this._updateData('accounting_at', date)
                    }}
                  />
                </div>
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="required fs-6 fw-bold form-label mb-2">
                    Sustainability Report Upload
                  </label>
                  <Dropzone
                    type="application/pdf"
                    subtitle="Upload a .PDF"
                    multi={false}
                    onUpload={(file) => {
                      this.setState({ fileUploading: false })
                      this._updateData('file_url', file.url)
                    }}
                    isUploading={(value) =>
                      this.setState({ fileUploading: value })
                    }
                    onRemove={() => {
                      this.setState({ fileUploading: false })
                      delete data.file_url
                    }}
                  />
                </div>
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="fs-6 fw-bold form-label mb-2">
                    Video Link
                  </label>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      value={data?.video_link}
                      name="title"
                      onChange={(e) => {
                        this._updateData('video_link', e.target.value)
                      }}
                    />
                    <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                      <span className="svg-icon svg-icon-muted">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205Z"
                            fill="black"
                          />
                          <path
                            d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-10">
                  <div className="border-bottom border-gray-300 mw-50 w-100" />
                  <span className="fw-bold text-gray-400 fs-7 mx-2">OR</span>
                  <div className="border-bottom border-gray-300 mw-50 w-100" />
                </div>
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="fs-6 fw-bold form-label mb-2">
                    Video Upload
                  </label>
                  <Dropzone
                    type="video/mp4"
                    subtitle="Upload a MP4 video"
                    multi={false}
                    onUpload={(file) => {
                      this.setState({ fileUploading: false })
                      this._updateData('video_url', file.url)
                      delete data.video_link
                    }}
                    isUploading={(value) =>
                      this.setState({ fileUploading: value })
                    }
                    onRemove={() => {
                      this.setState({ fileUploading: false })
                      delete data.video_url
                    }}
                  />
                </div>
                <div className="text-center pt-15">
                  <button
                    type="button"
                    className="btn btn-light me-3"
                    data-bs-dismiss="modal"
                    onClick={() => this.props.history.push('/')}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    id="upload-success"
                    className="btn btn-primary"
                    disabled={isLoading || fileUploading}
                    data-bs-dismiss="modal"
                    onClick={() => this._submitReport()}
                  >
                    {isLoading || fileUploading
                      ? 'Please wait...'
                      : 'Submit Report'}
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
