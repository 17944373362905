import React from 'react'

import SustainabilityTable from './components/tables/SustainabilityTable'
export default class Sustainability extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  render() {
    return (
      <>
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <SustainabilityTable title={'Avolon Sustainability Reports'} />
          </div>
        </div>
      </>
    )
  }
}
