import React from "react";

import Modal from "react-bootstrap/Modal";

import QRCode from "react-qr-code";

import Notify from "../../../../utils/Notify";
import AuthManager from "../../../../utils/AuthManager";


export default class ActivateMFAMethodModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      verificationMethod: props.verificationMethod,
    };
  }

  componentWillReceiveProps(nextProps) {
    let requestCode = nextProps.show && !this.state.show;
    this.setState(nextProps, () => {
      if (requestCode) {
        this._requestCode();
      }
    });
  }

  _isFormValid() {
    let { code } = this.state;

    let error = null;

    if (!code) {
      error = "Please enter a valid code.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }
    return true;
  }

  _activateCode() {
    const { verificationMethod, code } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    AuthManager.activateCode(verificationMethod.method, code)
      .then((backupCodes) => {
        this.props.onActivated(backupCodes);
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _title() {
    const { verificationMethod } = this.state;

    let method = verificationMethod.method;
    let title = "";

    if (method === "app") {
      return "Connect an Authenticator App";
    } else if (method === "sms_api") {
      return `Connect Your ${window.General.Branding.Name} App`;
    } else {
      return "SMS Verification";
    }
  }

  _requestCode(showAlert = true) {
    const { verificationMethod } = this.state;

    this.setState({ isLoading: true });

    AuthManager.requestCode(verificationMethod.method)
      .then(() => {
        this.setState({ isLoading: false });
        if (showAlert) {
          Notify.success("Code resent successfully");
        }
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _renderMethodContent() {
    const { verificationMethod } = this.props;

    let method = verificationMethod.method;
    switch (method) {
      case "app":
        return this._renderAppMethodContent();
      case "sms_twilio":
        return this._renderSMSTwilioMethodContent();
      default:
        return null;
    }
  }

  _renderSMSTwilioMethodContent() {
    let phoneNumber = `${AuthManager.currentUser.user.phone_country_code} ${AuthManager.currentUser.user.phone_number}`;

    return (
      <div className="text-dark">
        A text message with a code has been sent to
        <span className="font-weight-bold ml-1">{phoneNumber}.</span>
      </div>
    );
  }

  _renderAppMethodContent() {
    const { verificationMethod } = this.state;

    const qrCodeUrl = verificationMethod?.details;

    return (
      <>
        <div>
          <span className="text-dark my-3">
            Connect an authenticator app that generates verification codes. You
            can use the codes when we need to verify your identity.
          </span>
          <div>
            <ul className="mt-4 pb-2" style={{ listStyleType: "decimal" }}>
              <li>
                Download and install an authenticator app on your mobile device.
              </li>
              <li>Use the app to scan this QR code.</li>
              <li>Enter the code generated by the app.</li>
            </ul>
          </div>
        </div>
        {qrCodeUrl && (
          <div className="text-center">
            <QRCode
              size={100}
              style={{
                height: "auto",
                maxWidth: "50%",
                width: "50%",
                margin: "1rem auto",
              }}
              value={qrCodeUrl}
            />
          </div>
        )}
      </>
    );
  }

  render() {
    let { show, isLoading, code} = this.state;

    const { verificationMethod } = this.props;

    let method = verificationMethod.method;

    return (
      <>
        <Modal show={show} onHide={() => this.props.onHide()}>
          <Modal.Header closeButton>
            <Modal.Title>{this._title()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this._renderMethodContent()}
            <div className="text-dark-75 font-weight-bold mt-8">
              Enter 6-digit code
            </div>
            <div className="form-group row">
              <div className="col">
                <input
                  className="form-control form-control-solid"
                  type="text"
                  name="code"
                  value={code}
                  onChange={(e) => {
                    code = e.target.value;
                    this.setState({ code });
                  }}
                />
                {method !== "app" && (
                  <>
                    <span
                      className="text-muted ml-1"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Didn't receive a code ?
                      <a
                        href="javascript:;"
                        tabIndex="4"
                        className="text-primary font-weight-bold text-hover-primary pt-5 ml-1"
                        onClick={() => this._requestCode()}
                      >
                        Resend Code
                      </a>
                    </span>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary font-weight-bold px-9 py-3"
              disabled={isLoading}
              onClick={() => this._activateCode()
              }
            >
              Activate
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

ActivateMFAMethodModal.defaultProps = {};


