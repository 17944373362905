import React from "react";

import BaseTable from "./BaseTable";
import YearPicker from "../common/YearPicker";

import moment from "moment";

import { Link } from "react-router-dom";

import FileCell from "./cells/FileCell";
import ReportActionCell from "./cells/ReportActionCell";
import ConfirmModal from "../modals/ConfirmModal";
import ReportModal from "../modals/ReportModal";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import DateTime from "../../../utils/DateTime";

import InvestorActivityTable from "./InvestorActivityTable";

export default class ResultsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      endpoint: props.endpoint,
      selectedDate: moment().format("YYYY"),
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh());
  }

  _deleteReport() {
    let { selectedItem } = this.state;
    Backend.deleteReport(selectedItem)
      .then(() => {
        Notify.success("Report deleted successfully");
        this._refresh();
        this.setState({ selectedItem: null });
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _refresh() {
    this.table.current.refresh();
  }

  _getColumns() {
    return [
      {
        Header: "Title",
        id: "title",
        sortable: false,
        accessor: "title",
      },
      {
        Header: "Downloads",
        id: "no_of_downloads",
        sortable: false,
        accessor: "no_of_downloads",
      },
      {
        Header: "Release",
        id: "release_url",
        sortable: false,
        Cell: (rowInfo) => {
          if (!rowInfo.original?.release_url) {
            return "-";
          }
          return (
            <FileCell
              url={rowInfo.original?.release_url}
              slug={rowInfo.original?.slug}
            />
          );
        },
      },
      {
        Header: "Presentation",
        id: "presentation_url",
        sortable: false,
        Cell: (rowInfo) => {
          if (!rowInfo.original?.presentation_url) {
            return "-";
          }
          return (
            <FileCell
              url={rowInfo.original?.presentation_url}
              slug={rowInfo.original?.slug}
            />
          );
        },
      },
      {
        Header: "Webcast",
        id: "webcast_url",
        sortable: false,
        Cell: (rowInfo) => {
          if (!rowInfo.original?.webcast_url) {
            return "-";
          }
          return (
            <FileCell
              url={rowInfo.original?.webcast_url}
              slug={rowInfo.original?.slug}
            />
          );
        },
      },
      {
        Header: "Transcript",
        id: "transcript_url",
        sortable: false,
        Cell: (rowInfo) => {
          if (!rowInfo.original?.transcript_url) {
            return "-";
          }
          return (
            <FileCell
              url={rowInfo.original?.transcript_url}
              slug={rowInfo.original?.slug}
            />
          );
        },
      },
      {
        Header: "Event Detail",
        id: "event_detail_url",
        sortable: false,
        Cell: (rowInfo) => {
          if (!rowInfo.original?.event_detail_url) {
            return "-";
          }
          return (
            <FileCell
              url={rowInfo.original?.event_detail_url}
              slug={rowInfo.original?.slug}
            />
          );
        },
      },
      {
        id: "active",
        Header: "",
        sortable: false,
        width: 100,
        exportable: false,
        Cell: (rowInfo) => {
          return (
            <ReportActionCell
              item={rowInfo.original}
              onDeleteClicked={(item) => {
                this.setState({
                  showDeleteModal: true,
                  selectedItem: item,
                });
              }}
              onEditClicked={(item) => {
                this.setState({
                  showReportModal: true,
                  selectedItem: item,
                });
              }}
            />
          );
        },
      },
    ];
  }

  _renderToolbar() {
    return (
      <div className="card-toolbar">
        <button
          className="btn btn-primary my-5 me-10"
          data-bs-toggle="modal"
          data-bs-target="#add-result"
          onClick={() => {
            this.setState({ showReportModal: true });
          }}
        >
          <span className="btn-label">Add Report</span>
          <span className="svg-icon btn-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z"
                fill="black"
              />
              <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
            </svg>
          </span>
        </button>
        <YearPicker
          activeItem={this.state.selectedDate}
          onItemClicked={(item) => {
            this.setState({ selectedDate: item }, () => this._refresh());
          }}
        />
      </div>
    );
  }

  _renderResultsDetails(report) {
    return <InvestorActivityTable report={report} showExport={false} />;
  }

  render() {
    let { selectedDate } = this.state;
    const columns = this._getColumns();

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={
            selectedDate === "Archive"
              ? window.Api.Reports +
                "?category=investor_results&archive_year=" +
                DateTime.getArchiveYear()
              : window.Api.Reports +
                "?category=investor_results&year=" +
                selectedDate
          }
          showFilter={false}
          className="card mb-5 mb-xl-8"
          noDataMessage={"No results found"}
          title={this.props.title}
          renderToolbar={() => this._renderToolbar()}
          SubComponent={(row) => this._renderResultsDetails(row.original)}
          columns={columns}
          showExport={false}
          showPagination={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
        />
        <ReportModal
          mode={this.state.selectedItem ? "edit" : "add"}
          data={this.state.selectedItem}
          type={"investor_results"}
          show={this.state.showReportModal}
          onHide={() =>
            this.setState({ showReportModal: false, selectedItem: null })
          }
          onUpdated={() =>
            this.setState({ showReportModal: false }, () => {
              this._refresh();
            })
          }
        />
        <ConfirmModal
          show={this.state.showDeleteModal}
          title="Delete Report"
          message="Are you sure you want to delete this report?"
          onHide={() => this.setState({ showDeleteModal: false })}
          onConfirm={() => {
            this.setState({ showDeleteModal: false }, () => {
              this._deleteReport();
            });
          }}
        />
      </div>
    );
  }
}

ResultsTable.defaultProps = {
  latestResults: false,
};
