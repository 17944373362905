import React from "react";

import BaseTable from "./BaseTable";

import moment from "moment";

import General from "../../../utils/General";

export default class InvestorActivityTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      investor: props.investor,
      report: props.report,
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh());
  }

  _refresh() {
    this.table.current.refresh();
  }

  _getEmailAction(actions, selectedAction) {
    if (!actions || !selectedAction) {
      return null;
    }

    const actionIsInArray = actions.some((action) =>
      selectedAction.includes(action)
    );

    return (
      <>
        <span>
          {actionIsInArray && selectedAction === "opened"
            ? "Opened"
            : "Received"}
        </span>
        <span className="text-primary"> Email</span>
      </>
    );
  }

  _getColumns() {
    const { investor } = this.state;

    return [
      {
        Header: `${investor ? "Investor Activity" : "Downloads"}`,
        id: "id",
        Cell: (rowInfo) => {
          let investorData = rowInfo.original;

          if (!investorData) {
            return null;
          }

          let actions = investorData?.actions || null;
          const selectedAction = "opened";

          const isDownloadedInArray = actions.includes("downloaded");

          const action = actions?.map((action) => General.toTitleCase(action));

          const reportTitle = investorData.report?.title || "";

          const investorName =
            `${investorData.investor.user.first_name} ${investorData?.investor.user.last_name}` ||
            "";

          const createdAt =
            moment(investorData.created_at).format("Do MMMM YYYY [at] HH:mm") ||
            "";

          return (
            <div>
              <span>
                {!isDownloadedInArray &&
                  this._getEmailAction(actions, selectedAction)}

                {isDownloadedInArray && (
                  <>
                    {investor ? (
                      action
                    ) : (
                      <span className="fw-bold text-dark">{investorName}</span>
                    )}
                    {investor && (
                      <span className="text-primary ml-1">{reportTitle}</span>
                    )}
                  </>
                )}
              </span>
              <span className="ml-1">on {createdAt}</span>
            </div>
          );
        },
      },
    ];
  }

  render() {
    let { investor, report } = this.state;

    const columns = this._getColumns();

    const query = investor
      ? `investor_id=${investor.user.id}`
      : `report_id=${report.id}&download_only=true`;

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.InvestorActivities}?${query}&paginate=true`}
          noDataMessage={"No data found"}
          title={this.props.title}
          columns={columns}
          showExport={false}
          showHeader={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          showSearch={false}
        />
      </div>
    );
  }
}

InvestorActivityTable.defaultProps = {};
