import React from 'react'

import BaseTable from './BaseTable'

import moment from 'moment'

import { Link } from 'react-router-dom'

import UserCell from './cells/UserCell'
import ActionCell from './cells/ActionCell'

import UsersModal from '../modals/UserModal'
import ConfirmModal from '../modals/ConfirmModal'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'

export default class RecentSignUpsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh())
  }

  _refresh() {
    this.table.current.refresh()
  }

  _deleteUser() {
    let { selectedUser } = this.state
    Backend.deleteUser(selectedUser.user)
      .then(() => {
        Notify.success('User deleted successfully')
        this._refresh()
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  _getColumns() {
    return [
      {
        Header: 'User',
        sortable: false,
        Cell: (rowInfo) => {
          return <UserCell user={rowInfo.original.user} />
        },
        width: 220,
      },
      {
        Header: 'Company',
        id: 'company',
        sortable: false,
        accessor: 'company',
      },
      {
        Header: 'Job Title',
        id: 'job_title',
        sortable: false,
        accessor: 'job_title',
      },
      {
        Header: 'Signup Date',
        id: 'user.created_at',
        sortable: false,
        accessor: (row) => moment(row.user.created_at).format('DD MMM YYYY'),
      },
      {
        Header: 'Last Activity',
        id: 'user.last_login',
        accessor: (row) => {
          if (!row.user.last_login) {
            return 'No login'
          }
          return moment(row.user.last_login).format('DD MMM YYYY')
        },
        type: 'date',
        format: '%d %b %Y',
        sortable: false,
      },
      {
        id: 'active',
        Header: '',
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          return (
            <ActionCell
              item={rowInfo.original}
              onDeleteClicked={(item) => {
                this.setState({
                  showDeleteModal: true,
                  selectedUser: item,
                })
              }}
              onEditClicked={(item) => {
                this.setState({
                  showUserModal: true,
                  selectedUser: item,
                })
              }}
            />
          )
        },
      },
    ]
  }

  render() {
    let {} = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Investors + '?recent=true'}
          showFilter={false}
          noDataMessage={'No sign ups found'}
          title={this.props.title}
          columns={columns}
          showHeader={false}
          showPagination={false}
          showExport={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
        />
        <UsersModal
          mode={this.state.selectedUser ? 'edit' : 'add'}
          data={
            this.state.selectedUser
              ? { ...this.state.selectedUser.user, ...this.state.selectedUser }
              : null
          }
          show={this.state.showUserModal}
          onUpdated={() => {
            this.setState({ showUserModal: false, selectedUser: null }, () => {
              this._refresh()
            })
          }}
          onHide={() =>
            this.setState({ showUserModal: false, selectedUser: null })
          }
        />
        <ConfirmModal
          show={this.state.showDeleteModal}
          title="Delete User"
          message="Are you sure you want to delete this user?"
          onHide={() => this.setState({ showDeleteModal: false })}
          onConfirm={() => {
            this.setState({ showDeleteModal: false }, () => {
              this._deleteUser()
            })
          }}
        />
      </div>
    )
  }
}

RecentSignUpsTable.defaultProps = {
  latestResults: false,
}
