import moment from 'moment-timezone'
export default class DateTime {
  static convertTime(time) {
    let hour = time.split(':')[0]
    let min = time.split(':')[1]
    let part = hour > 12 ? 'PM' : 'AM'

    min = (min + '').length == 1 ? `0${min}` : min
    hour = hour > 12 ? hour - 12 : hour
    hour = (hour + '').length == 1 ? `0${hour}` : hour

    return `${hour}:${min} ${part}`
  }

  static getArchiveYear() {
    return new Date().getFullYear() - 3
  }

  static getArchiveYearV2() {
    return new Date().getFullYear() - 5
  }
  
  static formatDate(date, timezone, format = 'Do MMM YYYY') {
    return moment(date).tz(timezone).format(format)
  }

  static formatTime(time, timezone, format = 'hh:mm A') {
    return moment(time).tz(timezone).format(format)
  }

  static getTimezoneAbbreviation(timezone) {
    return moment.tz(timezone).zoneAbbr()
  }
}
