import React from 'react'
import Backend from '../../../../utils/Backend'
import Notify from '../../../../utils/Notify'
export default class ActionCell extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      item: props.item,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    let { item } = this.state

    return (
      <div>
        {this.props.showDownloadButton && (
          <a
            href={item.file_url}
            target="_blank"
            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            title
            data-bs-original-title="Download Presentation"
            style={{ marginLeft: 8 }}
          >
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
            <span className="svg-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z"
                  fill="black"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        )}
        {this.props.onDeleteClicked && (
          <a
            onClick={() => this.props.onDeleteClicked(item)}
            className="btn btn-icon btn-white btn-sm border-0 block-user"
            style={{ marginLeft: 8 }}
          >
            <span
              className="svg-icon svg-icon-2x svg-icon-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title
              data-bs-original-title="Block User"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.3"
                  x={2}
                  y={2}
                  width={20}
                  height={20}
                  rx={5}
                  fill="black"
                />
                <rect
                  x={7}
                  y="15.3137"
                  width={12}
                  height={2}
                  rx={1}
                  transform="rotate(-45 7 15.3137)"
                  fill="black"
                />
                <rect
                  x="8.41422"
                  y={7}
                  width={12}
                  height={2}
                  rx={1}
                  transform="rotate(45 8.41422 7)"
                  fill="black"
                />
              </svg>
            </span>
          </a>
        )}
        {this.props.onEditClicked && (
          <a
            onClick={() => this.props.onEditClicked(item)}
            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary mr-2"
            data-bs-toggle="modal"
            data-bs-target="#edit-user"
            style={{ marginLeft: 8 }}
          >
            <span
              className="svg-icon svg-icon-2"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title
              data-bs-original-title="Edit User"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                  fill="black"
                />
                <path
                  d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                  fill="black"
                />
                <path
                  d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                  fill="black"
                />
              </svg>
            </span>
          </a>
        )}
      </div>
    )
  }
}

ActionCell.defaultProps = {
  showDownloadButton: false,
}
