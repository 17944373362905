import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import { CopyToClipboard } from "react-copy-to-clipboard";

import Notify from "../../../../utils/Notify";

import copyIcon from "../../../../assets/media/png/copy-icon.png"


export default class BackupCodesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      backupCodes: props.backupCodes,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    const { show, backupCodes } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {}}
        dialogClassName={"modal-dialog-centered modal-md"}
        className={"sub-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Backup Codes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <div className="my-2">
                <span className="text-dark">
                  Store your backup codes somewhere safe, you can only use the
                  below backup codes with this method.
                  <br/><br/>
                  <b>Note: You can only use each backup code once</b>
                </span>
              </div>
              <div className="bg-light" style={{position: "relative", padding: 20}}>
                {
                  backupCodes.map((backupCode) => {
                    return (
                      <div className="row">
                        <div className="col pb-1">{backupCode}</div>
                      </div>
                    )
                  })
                }
                <div style={{
                  position: "absolute",
                  top: 5,
                  right: -100,
                  cursor: "pointer"
                }}>
                  <CopyToClipboard
                    text={backupCodes}
                    onCopy={() => {
                      Notify.success(`Copied to clipboard`);
                    }}
                  >
                    <img src={copyIcon} width="15%" alt="Copy Icon"/>
                  </CopyToClipboard>
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-warning font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            I Have Stored The Backup Codes Securely
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

BackupCodesModal.defaultProps = {
};
