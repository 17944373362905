import React, { Component } from 'react'
import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()
export default class Footer extends Component {
  render() {
    return (
      <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted fw-bold me-1">
              {new Date().getFullYear()}©
            </span>
            <a
              href="https://avolon.aero"
              target="_blank"
              className="text-gray-800 text-hover-primary"
            >
              Avolon
            </a>
          </div>
          <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
            <li className="menu-item">
              <a
                onClick={() => {
                  window.location = 'https://avolon.aero/about-us'
                }}
                target="_blank"
                className="menu-link px-2"
              >
                About
              </a>
            </li>
            <li className="menu-item">
              <a
                onClick={() => {
                  window.location = 'https://avolon.aero/contact'
                }}
                target="_blank"
                className="menu-link px-2"
              >
                Support
              </a>
            </li>
          </ul>
          {/*end::Menu*/}
        </div>
        {/*end::Container*/}
      </div>
    )
  }
}
