import React from 'react'
import OthersTable from './components/tables/OthersTable'

export default class Others extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }
  render() {
    return (
      <>
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <OthersTable title={'Other Files'} />
          </div>
        </div>
      </>
    )
  }
}
