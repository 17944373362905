import React from 'react'
import ResultsModal from '../modals/ResultsModal'
import CustomSvg from '../common/CustomSvg'

import Backend from '../../../utils/Backend'
import DateTime from '../../../utils/DateTime'

const timezone = 'America/New_York'
export default class UpcomingResultsCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      upcomingResults: null,
    }
    this._getUpcomingResults()
  }

  _getUpcomingResults() {
    this.setState({
      isLoading: true,
    })
    Backend.getUpcomingResults()
      .then((response) => {
        this.setState({
          upcomingResults: response,
          latestUpcomingResult: response[0],
          isLoading: false,
        })
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        })
      })
  }

  _renderFile() {
    let { latestUpcomingResult } = this.state
    if (!latestUpcomingResult.file_url) {
      return null
    }

    let url = latestUpcomingResult.file_url

    let fileType = url.split('.').pop()

    if (!url.includes('cloudfront')) {
      fileType = 'link'
    }
    return (
      <a
        href={url}
        target="_blank"
        className="text-dark fw-bolder text-hover-primary mb-1 fs-6"
      >
        <div className="symbol symbol-40px me-5">
          <CustomSvg text={fileType} />
        </div>
      </a>
    )
  }

  render() {
    let { latestUpcomingResult, isLoading } = this.state
    return (
      <div className="col-md-12">
        <a
          href="#"
          className="card border border-gray-300 rounded card-xl-stretch mb-5 bgi-no-repeat"
          style={{
            backgroundPosition: 'right top',
            backgroundSize: '30% auto',
            backgroundImage: 'url(assets/media/svg/shapes/abstract-2.svg',
          }}
        >
          <div className="card-header border-0 pt-9">
            <div className="card-title m-0">
              <div className="fs-3 fw-bolder text-primary">Upcoming Event</div>
            </div>
            <div className="card-toolbar">
              <span
                className="btn btn-primary fw-bolder me-auto w-150px px-4 py-3"
                data-bs-toggle="modal"
                data-bs-target="#upcoming-result"
                onClick={() =>
                  this.setState({
                    showUpcomingResultModal: true,
                  })
                }
              >
                Add New
              </span>
              <span
                className="btn btn-primary fw-bolder me-auto w-150px px-4 py-3 mx-3"
                data-bs-toggle="modal"
                data-bs-target="#upcoming-result"
                onClick={() =>
                  this.setState({
                    showUpcomingResultModal: true,
                    data: latestUpcomingResult,
                  })
                }
              >
                Edit
              </span>
            </div>
          </div>
          {!latestUpcomingResult && !isLoading && (
            <div className="card-body p-9">
              <p className="text-gray-600 fw-bold fs-4 mt-1 mb-4">
                No Upcoming Events
              </p>
            </div>
          )}
          {latestUpcomingResult && (
            <div className="card-body p-9">
              <p className="text-gray-600 fw-bold fs-4 mt-1 mb-4">
                <span className="text-dark me-3">Title:</span>
                {latestUpcomingResult.title}
              </p>

              <p className="text-gray-400 fw-bold fs-5 mt-1 mb-4">
                <span className="text-dark me-3">Issue Date & Time:</span>{' '}
                {DateTime.formatDate(latestUpcomingResult.issue_at, timezone)}-{' '}
                {DateTime.formatTime(latestUpcomingResult.issue_at, timezone)}{' '}
                {DateTime.getTimezoneAbbreviation(timezone)}
              </p>

              {latestUpcomingResult.issue_at && (
                <p className="text-gray-400 fw-bold fs-5 mt-1 mb-4">
                  <span className="text-dark me-3">Event Date & Time:</span>{' '}
                  {DateTime.formatDate(latestUpcomingResult.due_date, timezone)}
                  -{' '}
                  {DateTime.formatTime(latestUpcomingResult.due_date, timezone)}{' '}
                  {DateTime.getTimezoneAbbreviation(timezone)}
                </p>
              )}
              {latestUpcomingResult.file_url && (
                <p className="text-gray-400 fw-bold fs-5 mt-1 mb-7">
                  <span className="text-dark me-3">Event Detail</span>
                  {this._renderFile()}
                </p>
              )}
            </div>
          )}

          {!latestUpcomingResult && isLoading && (
            <div class="container">
              <div class="text-center mt-5">
                <div class="spinner-border text-primary mb-10" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          )}
        </a>
        <ResultsModal
          mode={this.state.data ? 'edit' : 'add'}
          data={this.state.data}
          show={this.state.showUpcomingResultModal}
          onHide={() =>
            this.setState({ showUpcomingResultModal: false, data: null })
          }
          onUpdated={(res) =>
            this.setState({
              data: null,
              latestUpcomingResult: res,
              showUpcomingResultModal: false,
            })
          }
        />
      </div>
    )
  }
}
