import React from "react";

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

import PhoneInput from "./components/common/PhoneInput";

export default class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: AuthManager.currentUser.user,
    };
  }

  _updateData(key, value) {
    let user = { ...this.state.user };
    user[key] = value;
    this.setState({ user });
  }

  _isValid() {
    let { user } = this.state;
    let error = null;
    if (!user) {
      error = "No data to submit";
    }
    if (!user.first_name || user.first_name === "") {
      error = "First name is required";
    }
    if (!user.last_name || user.last_name === "") {
      error = "Last name is required";
    }
    if (error) {
      Notify.error(error.message);
      return false;
    }
    return true;
  }

  _isEmailValid() {
    let { user } = this.state;
    let error = null;
    if (!user.email || user.email === "") {
      error = "Email is required";
    }
    if (error) {
      Notify.error(error.message);
      return false;
    }
    return true;
  }

  _isPasswordValid() {
    let { current_password, password, confirm_password } = this.state;
    let error = null;
    if (!current_password || current_password === "") {
      error = "Current password is required";
    }
    if (!password || password === "") {
      error = "New password is required";
    }
    if (password.length < 6) {
      error = "Password must be at least 6 characters";
    }
    if (!confirm_password || confirm_password === "") {
      error = "Confirm password is required";
    }
    if (password !== confirm_password) {
      error = "New password and confirm password must match";
    }
    if (error) {
      Notify.error(error.message);
      return false;
    }
    return true;
  }

  _updateUser() {
    let { user } = this.state;
    if (!this._isValid()) {
      return;
    }

    let data = {
      first_name: user.first_name,
      last_name: user.last_name,
      phone_country_code: user.phone_country_code,
      phone_number: user.phone_number,
    };

    this.setState({ isLoading: true });
    Backend.updateAdmin(data)
      .then((res) => {
        AuthManager.currentUser.user = res.user;

        this.setState({ isLoading: false });
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _updatePassword() {
    let { current_password, password } = this.state;
    if (!this._isPasswordValid()) {
      return;
    }
    let data = {
      current_password,
      password,
    };
    this.setState({ isLoading: true });
    Backend.updateAdmin(data)
      .then((res) => {
        this.setState({ isLoading: false, showUpdatePassword: false });
        Notify.success("Password updated successfully");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { user, showUpdatePassword, isLoading } = this.state;

    return (
      <div id="kt_content_container" className="container-xxl">
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Account Details</h3>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <form
              id="kt_account_profile_details_form"
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
              noValidate="novalidate"
            >
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Full Name
                  </label>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 fv-row fv-plugins-icon-container">
                        <input
                          type="text"
                          name="first_name"
                          value={user?.first_name}
                          className="form-control form-control-lg form-control-solid"
                          placeholder="First name"
                          onChange={(e) => {
                            this._updateData("first_name", e.target.value);
                          }}
                        />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                      <div className="col-lg-6 fv-row fv-plugins-icon-container">
                        <input
                          type="text"
                          value={user?.last_name}
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Last name"
                          onChange={(e) => {
                            this._updateData("last_name", e.target.value);
                          }}
                        />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    Phone Number
                  </label>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 fv-row fv-plugins-icon-container">
                        <div className="form-control form-control-solid" >
                          <PhoneInput
                            value={
                              user?.phone_country_code + user?.phone_number ||
                              "+353"
                            }
                            onChange={(value, phoneData) => {
                              user.phone_country_code = `+${phoneData.dialCode}`;
                              user.phone_number = value?.slice(
                                phoneData.dialCode.length
                              );

                              this.setState({
                                user,
                              });
                            }}
                            inputProps={{
                              autocomplete: "off",
                              className: "form-control form-control-solid phone-input-styles",
                            }}
                                                     
                          />
                          <div className="fv-plugins-message-container invalid-feedback" />
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div id="kt_account_signin_method" className="collapse show">
                    <div className="card-body border-top px-0">
                      <div className="d-flex flex-wrap align-items-center">
                        <div
                          id="kt_signin_password"
                          className={showUpdatePassword ? "d-none" : ""}
                        >
                          <div className="fs-6 fw-bolder mb-1">Password</div>
                          <div className="fw-bold text-gray-600">
                            ************
                          </div>
                        </div>
                        <div
                          id="kt_signin_password_edit"
                          className={
                            showUpdatePassword
                              ? "flex-row-fluid"
                              : "flex-row-fluid d-none"
                          }
                        >
                          <div className="row mb-1">
                            <div className="col-lg-4">
                              <div className="fv-row mb-0">
                                <label
                                  htmlFor="currentpassword"
                                  className="form-label fs-6 fw-bolder mb-3"
                                >
                                  Current Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-lg form-control-solid"
                                  name="currentpassword"
                                  id="currentpassword"
                                  value={this.state.current_password}
                                  onChange={(e) => {
                                    this.setState({
                                      current_password: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="fv-row mb-0">
                                <label
                                  htmlFor="newpassword"
                                  className="form-label fs-6 fw-bolder mb-3"
                                >
                                  New Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-lg form-control-solid"
                                  name="newpassword"
                                  value={this.state.password}
                                  id="newpassword"
                                  onChange={(e) => {
                                    this.setState({
                                      password: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="fv-row mb-0">
                                <label
                                  htmlFor="confirmpassword"
                                  className="form-label fs-6 fw-bolder mb-3"
                                >
                                  Confirm New Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-lg form-control-solid"
                                  name="confirmpassword"
                                  id="confirmpassword"
                                  value={this.state.confirm_password}
                                  onChange={(e) => {
                                    this.setState({
                                      confirm_password: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-text mb-5">
                            Password must be at least 6 character
                          </div>
                          <div className="d-flex">
                            <button
                              id="kt_password_submit"
                              type="button"
                              className="btn btn-primary me-2 px-6"
                              onClick={() => {
                                this._updatePassword();
                              }}
                            >
                              Update Password
                            </button>
                            <button
                              id="kt_password_cancel"
                              type="button"
                              className="btn btn-color-gray-400 btn-active-light-primary px-6"
                              onClick={() => {
                                this.setState({
                                  showUpdatePassword: false,
                                });
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                        <div
                          id="kt_signin_password_button"
                          className={showUpdatePassword ? "d-none" : "ms-auto"}
                        >
                          <button
                            className="btn btn-light btn-active-light-primary"
                            type="button"
                            onClick={() => {
                              this.setState({
                                showUpdatePassword: true,
                              });
                            }}
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <input type="hidden" />
            <div />
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="button"
                onClick={() => {
                  this.props.history.push("/");
                }}
                className="btn btn-light btn-active-light-primary me-2"
              >
                Discard
              </button>
              <button
                type="button"
                disabled={isLoading}
                className="btn btn-primary"
                id="kt_account_profile_details_submit"
                onClick={() => this._updateUser()}
              >
                Save Changes
              </button>
            </div>
            <input type="hidden" />
            <div />
          </div>
        </div>
      </div>
    );
  }
}
