import React from 'react'
import FilesTable from './components/tables/FilesTable'
import FileModal from './components/modals/FileModal'
import YearPicker from './components/common/YearPicker'
import moment from 'moment'
import UnsecuredNotesTable from './components/tables/UnsecuredNotesTable'
export default class Files extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedDate: moment().format('YYYY'),
    }
  }
  _renderHeader() {
    return (
      <div className="card-header card-header-stretch pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">
            Avolon File Library
          </span>
        </h3>
        <div className="card-toolbar">
          <a
            onClick={() => {
              this.setState({ showFileModal: true })
            }}
            className="btn btn-primary my-5 me-10"
            data-bs-toggle="modal"
            data-bs-target="#add-file"
          >
            <span className="btn-label">Add New File</span>
            <span className="svg-icon btn-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                  fill="black"
                />
                <path
                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z"
                  fill="black"
                />
              </svg>
            </span>
          </a>
          <YearPicker
            activeItem={this.state.selectedDate}
            onItemClicked={(item) => this.setState({ selectedDate: item })}
          />
        </div>
      </div>
    )
  }
  render() {
    return (
      <>
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <div className="card mb-5 mb-xl-8">
              {this._renderHeader()}
              <div className="card-body py-3 row">
                <div className="col-md-12">
                  <FilesTable
                    date={this.state.selectedDate}
                    onEditClicked={(item) => {
                      this.setState({ selectedItem: item })
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <UnsecuredNotesTable
                    date={this.state.selectedDate}
                    onEditClicked={(item) => {
                      this.setState({ selectedItem: item })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <FileModal
            show={this.state.showFileModal}
            mode={this.state.selectedItem ? 'edit' : 'add'}
            onHide={() => {
              this.setState({ showFileModal: false, selectedItem: null })
            }}
            onUpdated={() => {
              this.setState({ showFileModal: false, selectedItem: null })
            }}
          />
        </div>
      </>
    )
  }
}
