import React from "react";

import AuthManager from "../../../utils/AuthManager";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";

import VerificationMethod from "./VerificationMethod";

import Div from "../layouts/Div";

import DeactivateMFAMethodModal from "./modal/DeactivateMFAMethodModal";
import ActivateMFAMethodModal from "./modal/ActivateMFAMethodModal";
import RegenerateBackupCodesModal from "./modal/RegenerateBackupCodesModal";
import BackupCodesModal from "./modal/BackupCodesModal";
import DefaultMFAMethodModal from "./modal/DefaultMFAMethodModal";

import lockIcon from "../../../assets/media/icons/mfa/lock.svg";
import smsIcon from "../../../assets/media/icons/mfa/sms.svg";

export default class MultiFactorAuthentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMethods: null,
      isLoading: true,
      activeVerificationMethods: [],
      inactiveVerificationMethods: [],
    };
  }

  componentDidMount() {
    this._setActiveMethods();
  }

  _setActiveMethods() {
    this.setState({ isLoading: true });

    AuthManager.getActiveMethods()
      .then((activeMethods) => {
        let activeVerificationMethods = VERIFICATION_METHODS.filter(
          (verificationMethod) => {
            let activeMethod = activeMethods.find(
              (activeMethod) => activeMethod.name === verificationMethod.method
            );

            return activeMethod != null;
          }
        );

        let inactiveVerificationMethods = VERIFICATION_METHODS.filter(
          (verificationMethod) => {
            let activeMethod = activeMethods.find(
              (activeMethod) => activeMethod.name === verificationMethod.method
            );

            return activeMethod == null;
          }
        );

        this.setState({
          activeMethods,
          activeVerificationMethods,
          inactiveVerificationMethods,
          isLoading: false,
        });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _activateMethod(verificationMethod) {
    let selectedVerificationMethod = General.clone(verificationMethod);
    this.setState({ isLoading: true, selectedVerificationMethod });

    AuthManager.activateMethod(verificationMethod.method)
      .then((methodDetails) => {
        selectedVerificationMethod.details = methodDetails.details;
        this.setState({
          methodDetails,
          isLoading: false,
          showActivateMFAMethodModal: true,
        });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _deactivateMethod(verificationMethod) {
    this.setState({
      showDeactivateMFAMethodModal: true,
      selectedVerificationMethod: verificationMethod,
    });
  }

  _setDefaultMethod(verificationMethod) {
    this.setState({
      showDefaultMFAModal: true,
      selectedVerificationMethod: verificationMethod,
    });
  }

  _renderVerificationMethod(verificationMethod) {
    const { activeMethods } = this.state;

    let activeMethod = activeMethods.find(
      (method) => method.name == verificationMethod.method
    );

    verificationMethod.active = activeMethod != null;
    verificationMethod.primary = activeMethod?.is_primary;

    verificationMethod.canDeactivate =
      activeMethods.length == 1 || !verificationMethod.primary;

    return (
      <div className="verification-methods">
        <VerificationMethod
          activeMethods={activeMethods}
          verificationMethod={verificationMethod}
          onActivateClicked={() => this._activateMethod(verificationMethod)}
          onDeactivateClicked={() => this._deactivateMethod(verificationMethod)}
          onRegenerateBackupCodesClicked={() => {
            this.setState({
              showRegenerateBackupCodesModal: true,
              selectedVerificationMethod: verificationMethod,
            });
          }}
          onMakeDefaultClicked={() =>
            this._setDefaultMethod(verificationMethod)
          }
        />
      </div>
    );
  }

  render() {
    const {
      isLoading,
      backupCodes,
      activeMethods,
      showDefaultMFAModal,
      showBackupCodesModal,
      selectedVerificationMethod,
      showActivateMFAMethodModal,
      showDeactivateMFAMethodModal,
      showRegenerateBackupCodesModal,
      activeVerificationMethods,
      inactiveVerificationMethods,
    } = this.state;

    if (activeMethods == null) {
      return null;
    }

    let primaryMethod = activeMethods.find(
      (activeMethod) => activeMethod?.is_primary
    );

    return (
      <>
        <Div
          id="kt_content_container"
          className="container-xxl"
          disabled={isLoading}
        >
          <div className="card mb-5 mb-xl-10">
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
            >
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Multi Factor Authentication</h3>
              </div>
            </div>
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              <div className="fv-plugins-bootstrap5 fv-plugins-framework">
                <div className="card-body border-top p-9">
                  <p className="mb-10">
                    Increase security for your account by using multiple
                    authentication steps.
                  </p>

                  {activeVerificationMethods.map((verificationMethod) =>
                    this._renderVerificationMethod(verificationMethod)
                  )}

                  {inactiveVerificationMethods.length > 0 && (
                    <>
                      <div className="font-weight-bold font-size-h6 mb-5">
                        Add Authentication Step
                      </div>

                      {inactiveVerificationMethods.map((verificationMethod) =>
                        this._renderVerificationMethod(verificationMethod)
                      )}
                    </>
                  )}
                </div>
              </div>

              {primaryMethod && (
                <p className="text-grey pull-left mt-5 ml-16">
                  If you lose your mobile device you can
                  <a
                    href="javascript:;"
                    tabIndex="4"
                    className="text-primary font-weight-bold pt-5 ml-1 mr-1"
                    style={{ textDecoration: "underline" }}
                    onClick={() =>
                      this.setState({
                        showRegenerateBackupCodesModal: true,
                      })
                    }
                  >
                    <span className="text-span">generate backup codes</span>
                  </a>
                  to sign in to your account.
                </p>
              )}

              <div />
              <div />
            </div>
          </div>
          {showDeactivateMFAMethodModal && (
            <DeactivateMFAMethodModal
              show={showDeactivateMFAMethodModal}
              verificationMethod={selectedVerificationMethod}
              onHide={() =>
                this.setState({ showDeactivateMFAMethodModal: false })
              }
              onDeactivated={() => {
                this.setState(
                  {
                    showDeactivateMFAMethodModal: false,
                  },
                  () => this._setActiveMethods()
                );
              }}
            />
          )}
          {showActivateMFAMethodModal && (
            <ActivateMFAMethodModal
              show={showActivateMFAMethodModal}
              verificationMethod={selectedVerificationMethod}
              onHide={() =>
                this.setState({ showActivateMFAMethodModal: false })
              }
              onActivated={(backupCodes) => {
                this.setState(
                  {
                    backupCodes,
                    showBackupCodesModal: true,
                    showActivateMFAMethodModal: false,
                  },
                  () => this._setActiveMethods()
                );
              }}
            />
          )}
          {showBackupCodesModal && backupCodes && (
            <BackupCodesModal
              show={showBackupCodesModal}
              backupCodes={backupCodes}
              verificationMethod={selectedVerificationMethod}
              onHide={() => {
                this.setState(
                  {
                    backupCodes: null,
                    showBackupCodesModal: false,
                  },
                  () => this._setActiveMethods()
                );
              }}
            />
          )}
          {showRegenerateBackupCodesModal && (
            <RegenerateBackupCodesModal
              show={showRegenerateBackupCodesModal}
              verificationMethod={primaryMethod}
              onRegenerated={(backupCodes) => {
                this.setState({
                  backupCodes,
                  showBackupCodesModal: true,
                  showRegenerateBackupCodesModal: false,
                });
              }}
              onHide={() => {
                this.setState({
                  selectedVerificationMethod: null,
                  showRegenerateBackupCodesModal: false,
                });
              }}
            />
          )}
          {showDefaultMFAModal && (
            <DefaultMFAMethodModal
              show={showDefaultMFAModal}
              newVerificationMethod={selectedVerificationMethod}
              currentPrimaryMethodName={primaryMethod?.name}
              onUpdated={() => {
                this._setActiveMethods();
                this.setState({
                  showDefaultMFAModal: false,
                });
              }}
              onHide={() => {
                this.setState({
                  showDefaultMFAModal: false,
                });
              }}
            />
          )}
        </Div>
      </>
    );
  }
}

export const VERIFICATION_METHODS = [
  {
    title: "Phone Number",
    subtitle: "Receive codes on your phone via SMS.",
    method: "sms_twilio",
    icon: smsIcon,
  },
  {
    title: "Google Authenticator / Authy",
    subtitle: "Connect an authenticator app that generates codes.",
    method: "app",
    icon: lockIcon,
  },
];
