import React from "react";

import warningIcon from "../../../assets/media/icons/flaticon-warning.png"

const TYPES = {
  warning: "alert-custom alert-warning",
  info: "alert-custom alert-secondary",
  dark: "alert-custom alert-dark",
  note: "alert-custom alert-info",
  primary: "alert-custom alert-primary",
  danger: "alert-custom alert-danger",
};

export default class Alert extends React.Component {
  render() {
    return (
      <div className={`alert ${TYPES[this.props.type]} mb-4`} role="alert">
        {/* <div className="alert-icon"><i className={this.props.iconClassName}></i></div> */}
        <div className="alert-icon">
          <img
            src={warningIcon}
            alt="Alert "
            width="38"
            height="38"
          />
        </div>
        <div className="alert-text ">{this.props.text}</div>
        {this.props.closable && (
          <div className="alert-close">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="la la-close text-white"></i>
              </span>
            </button>
          </div>
        )}
        {this.props.cta && (
          <div className="alert-button">
            <button type="button" onClick={() => this.props.onClick()}>
              {this.props.cta}
            </button>
          </div>
        )}
      </div>
    );
  }
}

Alert.defaultProps = {
  text: "Placeholder Text",
  iconClassName: "flaticon-warning",
  closable: true,
  type: "warning",
};
