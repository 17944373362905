import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'
import TextEditor from '../common/TextEditor'
import Dropzone from '../common/CustomDropzone'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'

import moment from 'moment'
export default class CreateEmailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _updateData(key, value) {
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data })
  }

  _isFormValid() {
    let { subject, body } = this.state
    let error = null

    if (!subject) {
      error = 'Subject is required.'
    }
    if (!body) {
      error = 'Body is required.'
    }
    if (error) {
      Notify.error(error.message)
      return false
    }

    return true
  }

  _submitEmail() {
    let { subject, body, file } = this.state
    if (!this._isFormValid()) {
      return
    }
    let data = {
      subject,
      body,
    }
    data['issued_at'] = moment().format()
    if (file) {
      data.attachments = [{ file_url: file.url }]
    }
    this.setState({ isLoading: true })
    Backend.submitEmail(data)
      .then(() => {
        this.setState({
          isLoading: false,
          subject: null,
          body: null,
          file: null,
        })
        Notify.success('Email has been sent successfully.')
        this.props.onUpdated()
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { show, isLoading, fileUploading } = this.state
    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>Create An Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="edit-user-form" className="form" action="#">
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Subject</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="What is the subject of the email"
                  />
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder
                  value={this.state.subject}
                  name="user_name"
                  onChange={(e) => {
                    this.setState({ subject: e.target.value })
                  }}
                />
              </div>

              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Content</span>
                </label>
                <TextEditor
                  text={this.state.body}
                  className="form-control form-control-lg"
                  placeholder="Compose message here..."
                  onUpdated={(body) => {
                    this.setState({ body })
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span>Attachments</span>
                </label>
                <Dropzone
                  subtitle="Upload an attachment"
                  multi={false}
                  type="images/*, audio/*, application/*, video/*"
                  onUpload={(file) => {
                    this.setState({ fileUploading: false })
                    this.setState({ file })
                  }}
                  isUploading={(value) =>
                    this.setState({ fileUploading: value })
                  }
                  onRemove={() => {
                    this.setState({ fileUploading: false })
                    delete this.state.file
                  }}
                />
              </div>

              <div className="text-center pt-15">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  id="send-success"
                  className="btn btn-primary"
                  disabled={fileUploading || isLoading}
                  data-bs-dismiss="modal"
                  onClick={() => {
                    this._submitEmail()
                  }}
                >
                  <span className="indicator-label">
                    {isLoading ? 'Sending...' : 'Send Email'}
                  </span>
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
