import React from 'react'

import UsersTable from './components/tables/UsersTable'
export default class Users extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }
  render() {
    return (
      <div className="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" className="container-xxl">
          <UsersTable />
        </div>
      </div>
    )
  }
}
