import React from 'react'

import Modal from 'react-bootstrap/Modal'

import SmartList from '../common/SmartList'

import Backend from '../../../utils/Backend'

export default class ReorderModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDisabled: true,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderItem(item, index) {
    return (
      <div className="d-flex align-items-center" style={{ height: 40 }}>
        <span className="svg-icon btn-icon svg-icon-2 me-5">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="grip-vertical"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
            />
          </svg>
        </span>

        <img
          className="c-logo me-5"
          src={item.logo_url}
          style={{ width: 50 }}
        />

        <div className="d-flex justify-content-start flex-column">
          <label className="text-dark fw-bolder mb-1 fs-6">{item.name}</label>
        </div>
      </div>
    )
  }

  render() {
    let {
      show,
      title,
      isLoading,
      buttonClassName,
      confirmButtonText,
      cancelButtonText,
    } = this.props
    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SmartList
            endpoint={window.Api.CreditRatings}
            items={this.state.items}
            itemName="Credit Rating"
            value={(item) => item.name}
            disabled={(item) => false}
            onLoadedItems={(items) => {
              this.setState({ items })
            }}
            renderItem={(item, index) => {
              return this._renderItem(item, index)
            }}
            onUpdated={(items) => {
              this.setState({ items, isDisabled: false })
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={isLoading}
            onClick={() => this.props.onHide()}
          >
            {cancelButtonText}
          </button>

          <button
            type="button"
            className={`btn btn-${buttonClassName}`}
            disabled={this.state.isDisabled}
            onClick={() => {
              this.setState({ isDisabled: true })
              this.props.onUpdate(this.state.items)
            }}
          >
            {confirmButtonText}
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

ReorderModal.defaultProps = {
  title: 'Are you sure?',
  message: null,
  loading: false,
  buttonClassName: 'primary',
  confirmButtonText: 'Save Changes',
  cancelButtonText: 'Cancel',
}
