import React from 'react'

import BaseTable from './BaseTable'
import YearPicker from '../common/YearPicker'

import moment from 'moment'

import { Link } from 'react-router-dom'

import EditCell from './cells/EditCell'
import FileCell from './cells/FileCell'

import ReportModal from '../modals/ReportModal'

export default class PopularReports extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      endpoint: props.endpoint,
      selectedDate: moment().format('YYYY'),
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh())
  }

  _refresh() {
    this.table.current.refresh()
  }

  _getColumns() {
    return [
      {
        Header: 'Report',
        id: 'title',
        sortable: false,
        accessor: 'title',
      },
      {
        Header: 'Downloads',
        id: 'no_of_downloads',
        width: 120,
        sortable: false,
        accessor: 'no_of_downloads',
      },
    ]
  }

  render() {
    let {} = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Reports + '?top_downloads=true'}
          showFilter={false}
          className="card mb-5 mb-xl-8"
          noDataMessage={'No reports found'}
          title={this.props.title}
          columns={columns}
          showHeader={false}
          showExport={false}
          showPagination={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
        />
      </div>
    )
  }
}

PopularReports.defaultProps = {
  latestResults: false,
}
