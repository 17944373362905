import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'

import Dropzone from '../common/CustomDropzone'
import FlatPickr from 'react-flatpickr'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import General from '../../../utils/General'

import moment from 'moment'

export default class ReportModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data || {},
      type: props.type,
      showResultType: props.type ? true : false,
      show: props.show,
      mode: props.mode,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _updateData(key, value) {
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data })
  }

  _isFormValid() {
    let { data, type } = this.state

    let error = null
    if (!data.title) {
      error = 'Title is required.'
    }
    else if (!data.slug) {
      error = 'Slug is required.'
    }
    else if (!data.category && !type) {
      error = 'Result Type is required.'
    }
    else if (!data.published_at) {
      error = 'Release Date is required.'
    }
    else if (!data.accounting_at) {
      error = 'Account Date is required.'
    }

    if (error) {
      Notify.error(error)
      return false
    }

    return true
  }

  _updateResult() {
    let { data, mode, type } = this.state

    if (!this._isFormValid()) {
      return
    }
    data.is_live = true

    if (type) {
      data.category = type
    }
    if (data.hasOwnProperty('image') && !data.image) {
      delete data.image
    }
    this.setState({ isLoading: true })
    Backend.submitReport(data, mode)
      .then(() => {
        this.setState({
          isLoading: false,
        })
        Notify.success('Report has been updated successfully.')
        this.props.onUpdated()
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { data, mode, show, type, isLoading, fileUploading, showResultType } =
      this.state
    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>
              {mode === 'edit' ? 'Edit Report' : 'Add Report'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="edit-result-form" className="form" action="#">
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Result Title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="What is the name of the result"
                  />
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Q2 Result"
                  value={data?.title}
                  name="result_name"
                  onChange={(e) => {
                    this._updateData('title', e.target.value)
                  }}
                />
              </div>
              {mode === 'add' && (
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Slug</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder=""
                    value={data?.slug}
                    name="result_slug"
                    onChange={(e) => {
                      this._updateData('slug', e.target.value)
                    }}
                  />
                </div>
              )}
              {!showResultType && (
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Result Type</span>
                  </label>
                  <select
                    className="form-control form-control-solid"
                    value={data?.category}
                    onChange={(e) => {
                      this.setState({ type: e.target.value })
                      this._updateData('category', e.target.value)
                    }}
                  >
                    <option value="" selected disabled>
                      Select Result Type
                    </option>
                    <option value="investor_results">Reported Result</option>
                    <option value="investor_annual">Annual Result</option>
                  </select>
                </div>
              )}
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="required fs-6 fw-bold form-label mb-2">
                  Release Date
                </label>
                <FlatPickr
                  value={data?.published_at}
                  className="form-control"
                  options={{
                    enableTime: false,
                    noCalendar: false,
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    static: true,
                  }}
                  onChange={(dates) => {
                    let date = dates[0]
                    this._updateData('published_at', date)
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="required fs-6 fw-bold form-label mb-2">
                  Accounting Date
                </label>
                <FlatPickr
                  value={data?.accounting_at}
                  className="form-control"
                  options={{
                    enableTime: false,
                    noCalendar: false,
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    static: true,
                  }}
                  onChange={(dates) => {
                    let date = moment(dates[0]).format('YYYY-MM-DD')
                    this._updateData('accounting_at', date)
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="required fs-6 fw-bold form-label mb-2">
                  Upload Release
                </label>
                <Dropzone
                  type="application/pdf, application/msword"
                  subtitle="Upload a .PDF or .DOC"
                  multi={false}
                  onUpload={(file) => {
                    this.setState({ fileUploading: false })
                    this._updateData('release_url', file.url)
                  }}
                  isUploading={(value) =>
                    this.setState({ fileUploading: value })
                  }
                  onRemove={() => {
                    this.setState({ fileUploading: false })
                    delete data.release_url
                  }}
                />
              </div>
              {type !== 'investor_annual' && (
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="fs-6 fw-bold form-label mb-2">
                    Upload Presentation
                  </label>

                  <Dropzone
                    type="application/vnd.ms-powerpoint, application/pdf"
                    subtitle="Upload a .PPT or .PDF"
                    multi={false}
                    onUpload={(file) => {
                      this.setState({ fileUploading: false })
                      this._updateData('presentation_url', file.url)
                    }}
                    isUploading={(value) =>
                      this.setState({ fileUploading: value })
                    }
                    onRemove={() => {
                      this.setState({ fileUploading: false })
                      delete data.presentation_url
                    }}
                  />
                </div>
              )}
              {type !== 'investor_annual' && (
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="fs-6 fw-bold form-label mb-2">
                    Upload Webcast
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    value={data?.webcast_url}
                    name="title"
                    onChange={(e) => {
                      this._updateData('webcast_url', e.target.value)
                    }}
                  />
                </div>
              )}
              {type !== 'investor_annual' && (
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="fs-6 fw-bold form-label mb-2">
                    Upload Transcript
                  </label>
                  <Dropzone
                    type="application/pdf,application/msword"
                    subtitle="Upload a .PDF or .DOC"
                    multi={false}
                    onUpload={(file) => {
                      this.setState({ fileUploading: false })
                      this._updateData('transcript_url', file.url)
                    }}
                    isUploading={(value) =>
                      this.setState({ fileUploading: value })
                    }
                    onRemove={() => {
                      this.setState({ fileUploading: false })
                      delete data.transcript_url
                    }}
                  />
                </div>
              )}
              {type !== 'investor_annual' && (
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="fs-6 fw-bold form-label mb-2">
                    Upload Event Detail
                  </label>
                  <Dropzone
                    type="application/pdf,application/msword"
                    subtitle="Upload a .PDF or .DOC"
                    multi={false}
                    onUpload={(file) => {
                      this.setState({ fileUploading: false })
                      this._updateData('event_detail_url', file.url)
                    }}
                    isUploading={(value) =>
                      this.setState({ fileUploading: value })
                    }
                    onRemove={() => {
                      this.setState({ fileUploading: false })
                      delete data.event_detail_url
                    }}
                  />
                </div>
              )}
              <div className="text-center pt-15">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  id="edit-success"
                  className="btn btn-primary"
                  disabled={isLoading || fileUploading}
                  data-bs-dismiss="modal"
                  onClick={() => this._updateResult()}
                >
                  {isLoading || fileUploading
                    ? 'Please wait...'
                    : 'Save Changes'}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
