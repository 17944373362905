import React from 'react'

import BaseTable from './BaseTable'
import SustainabilityModal from '../modals/SustainabilityModal'
import ConfirmModal from '../modals/ConfirmModal'
import FileCell from './cells/FileCell'
import ReportActionCell from './cells/ReportActionCell'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
export default class SustainabilityTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      years: [],
      selectedYear: null,
      isLoading: true,
    }
    this._loadReports()
    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._reload())
  }

  _loadReports() {
    this.setState({ isLoading: true })
    Backend.getAllYearlyReports('investor_sustainability')
      .then((response) => {
        let years = this._getYears(response)
        this.setState(
          {
            selectedYear: years[0],
            data: response,
            reports: response.find((group) => group.year === years[0]).reports,
            years,
            isLoading: false,
          },
          () => {
            this._refresh()
          }
        )
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  _reload() {
    let { selectedYear } = this.state
    this.setState({ isLoading: true })
    Backend.getAllYearlyReports('investor_sustainability')
      .then((response) => {
        let years = this._getYears(response)
        this.setState(
          {
            data: response,
            reports: response.find((group) => group.year === selectedYear)
              .reports,
            years,
            isLoading: false,
          },
          () => {
            this._refresh()
          }
        )
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  _getYears(data) {
    let years = []
    data.map((reportGroup) => {
      years.push(reportGroup.year)
    })
    return years
  }

  _deleteReport() {
    let { selectedItem } = this.state
    Backend.deleteReport(selectedItem)
      .then(() => {
        Notify.success('Report deleted successfully')
        this._reload()
        this.setState({ selectedItem: null })
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  _refresh() {
    this.table.current.refresh()
  }

  _onYearClicked(year) {
    let { data } = this.state
    let reports = data.find((group) => group.year === year).reports
    this.setState({ selectedYear: year, reports }, () => {
      this._refresh()
    })
  }

  _renderYears() {
    let { years } = this.state
    if (years.length === 0) {
      return null
    }
    return years.map((year) => {
      return this._renderYear(year)
    })
  }

  _renderYear(year) {
    return (
      <li className="nav-item">
        <a
          className={
            this.state.selectedYear == year
              ? 'nav-link text-active-primary pb-4 active'
              : 'nav-link text-active-primary pb-4'
          }
          data-bs-toggle="tab"
          onClick={() => this._onYearClicked(year)}
        >
          {year}
        </a>
      </li>
    )
  }

  _getColumns() {
    return [
      {
        Header: 'title',
        id: 'title',
        sortable: false,
        accessor: 'title',
      },
      {
        Header: 'Subtitle',
        id: 'subtitle',
        sortable: false,
        accessor: 'subtitle',
      },
      {
        Header: 'Report',
        id: 'file_url',
        width: 120,
        sortable: false,
        Cell: (rowInfo) => {
          if (!rowInfo.original?.file_url) {
            return '-'
          }
          return (
            <FileCell
              url={rowInfo.original?.file_url}
              slug={rowInfo.original?.slug}
            />
          )
        },
      },
      {
        Header: 'Video',
        id: 'video_url',
        width: 120,
        sortable: false,
        Cell: (rowInfo) => {
          if (!rowInfo.original?.video_url) {
            return '-'
          }
          return (
            <FileCell
              url={rowInfo.original?.video_url}
              fileType={'mp4'}
              slug={rowInfo.original?.slug}
            />
          )
        },
      },
      {
        id: 'active',
        Header: '',
        sortable: false,
        exportable: false,
        width: 100,
        Cell: (rowInfo) => {
          return (
            <ReportActionCell
              item={rowInfo.original}
              onDeleteClicked={(item) => {
                this.setState({
                  showDeleteModal: true,
                  selectedItem: item,
                })
              }}
              onEditClicked={(item) => {
                this.setState({
                  showSustainabilityModal: true,
                  selectedItem: item,
                })
              }}
            />
          )
        },
      },
    ]
  }

  _renderToolbar() {
    return (
      <div className="card-toolbar">
        <button
          className="btn btn-primary my-5 me-10"
          data-bs-toggle="modal"
          data-bs-target="#add-result"
          onClick={() => {
            this.setState({ showSustainabilityModal: true })
          }}
        >
          <span className="btn-label">Add Report</span>
          <span className="svg-icon btn-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                fill="black"
              />
              <path
                d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                fill="black"
              />
            </svg>
          </span>
        </button>
        <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-4 border-0">
          {this._renderYears()}
        </ul>
      </div>
    )
  }

  render() {
    let { reports, isLoading } = this.state
    const columns = this._getColumns()
    return (
      <div>
        <BaseTable
          ref={this.table}
          data={reports}
          endpoint=""
          showFilter={false}
          loading={isLoading}
          className="card mb-5 mb-xl-8"
          noDataMessage={'No reports found'}
          title={this.props.title}
          renderToolbar={() => this._renderToolbar()}
          columns={columns}
          showExport={false}
          showPagination={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
        />
        <SustainabilityModal
          show={this.state.showSustainabilityModal}
          data={this.state.selectedItem}
          mode={this.state.selectedItem ? 'edit' : 'add'}
          onHide={() =>
            this.setState({
              showSustainabilityModal: false,
              selectedItem: null,
            })
          }
          onUpdated={() =>
            this.setState({ showSustainabilityModal: false }, () => {
              this._reload()
            })
          }
        />
        <ConfirmModal
          show={this.state.showDeleteModal}
          title="Delete Report"
          message="Are you sure you want to delete this report?"
          onHide={() => this.setState({ showDeleteModal: false })}
          onConfirm={() => {
            this.setState({ showDeleteModal: false }, () => {
              this._deleteReport()
            })
          }}
        />
      </div>
    )
  }
}

SustainabilityTable.defaultProps = {
  latestResults: false,
}
