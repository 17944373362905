import React from 'react'

import BaseTable from './BaseTable'
import FileModal from '../modals/FileModal'

import ActionCell from './cells/ActionCell'

import ConfirmModal from '../modals/ConfirmModal'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import DateTime from '../../../utils/DateTime'

export default class FilesTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      date: props.date,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh())
  }

  _deleteFile() {
    let { selectedItem } = this.state
    Backend.deleteReport(selectedItem)
      .then(() => {
        Notify.success('File deleted successfully')
        this._refresh()
        this.setState({ selectedItem: null })
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  _refresh() {
    this.table.current.refresh()
  }

  _getColumns() {
    return [
      {
        Header: 'Offering Memoranda',
        id: 'title',
        accessor: 'title',
        sortable: false,
      },
      {
        id: 'active',
        Header: '',
        sortable: false,
        width: 150,
        exportable: false,
        Cell: (rowInfo) => {
          return (
            <ActionCell
              item={rowInfo.original}
              showDownloadButton
              onDeleteClicked={(item) => {
                this.setState({
                  showDeleteModal: true,
                  selectedItem: item,
                })
              }}
              onEditClicked={(item) => {
                this.setState({
                  showFileModal: true,
                  selectedItem: item,
                })
              }}
            />
          )
        },
      },
    ]
  }

  render() {
    let { date } = this.state
    const columns = this._getColumns()
    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={
            date === 'Archive'
              ? window.Api.Reports +
                '?category=offering_memoranda&archive_year=' +
                DateTime.getArchiveYear()
              : window.Api.Reports + '?category=offering_memoranda&year=' + date
          }
          showFilter={false}
          className="card mb-5 mb-xl-8"
          noDataMessage={'No files found'}
          title={this.props.title}
          showHeader={false}
          columns={columns}
          showExport={false}
          showPagination={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
        />
        <FileModal
          show={this.state.showFileModal}
          mode={this.state.selectedItem ? 'edit' : 'add'}
          data={this.state.selectedItem}
          onHide={() => {
            this.setState({ showFileModal: false, selectedItem: null })
          }}
          onUpdated={() => {
            this.setState({ showFileModal: false }, () => this._refresh())
          }}
        />
        <ConfirmModal
          show={this.state.showDeleteModal}
          title="Delete File"
          message="Are you sure you want to delete this file?"
          onHide={() => this.setState({ showDeleteModal: false })}
          onConfirm={() => {
            this.setState({ showDeleteModal: false }, () => {
              this._deleteFile()
            })
          }}
        />
      </div>
    )
  }
}

FilesTable.defaultProps = {}
