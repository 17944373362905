import React from 'react'

import Modal from 'react-bootstrap/Modal'

export default class ConfirmModal extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      title,
      message,
      isLoading,
      buttonClassName,
      confirmButtonText,
      cancelButtonText,
    } = this.props

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={isLoading}
            onClick={() => this.props.onHide()}
          >
            {cancelButtonText}
          </button>

          <button
            type="button"
            className={`btn btn-${buttonClassName}`}
            disabled={isLoading}
            onClick={() => this.props.onConfirm()}
          >
            {confirmButtonText}
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

ConfirmModal.defaultProps = {
  title: 'Are you sure?',
  message: null,
  loading: false,
  buttonClassName: 'primary',
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
}
