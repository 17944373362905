import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import AuthManager from "../../../utils/AuthManager";

import ConfirmModal from "../modals/ConfirmModal";

class VerificationMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationMethod: props.verificationMethod,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderMethods() {
    let { verificationMethod, showConfirmModal } = this.state;

    return this._renderMethod(verificationMethod, showConfirmModal);
  }

  _renderMethod(verificationMethod, showConfirmModal) {
    let method = verificationMethod.method;
    let isActiveMethod = verificationMethod.active;

    let phoneNumber = `${AuthManager.currentUser.user.phone_country_code} ${AuthManager.currentUser.user.phone_number}`;

    let primaryText = "(Primary)";

    const width = this.props.activeMethods.length <= 1 ? "55%" : "75%";

    return (
      <>

        <div 
          className={`verification-method d-flex flex-stack mb-5 ${isActiveMethod ? "activated" : "not-activated"}`} 
          style={{
            width,
          }}
        >

          {/* Starts : First Col */}
          <div className="d-flex align-items-center me-2" >

            <span className="symbol symbol-50px me-6">
              <span class="symbol-label">
                <img
                  className="symbol-img"
                  src={verificationMethod.icon}
                  alt={`${verificationMethod.title}`}
                />
              </span>
            </span>

            <span class="d-flex flex-column">
              <span class="fs-5">
                <span className="title">{verificationMethod.title}</span>
                {verificationMethod.primary && 
                  (
                    <span className="ml-1">{primaryText}</span>
                  )
                }
              </span>
              {verificationMethod.subtitle}
            </span>

          </div>
          {/* End : First Col */}

          {/* Starts : Buttons Column */}
          <div>
            {isActiveMethod ? (
              <div>
                {verificationMethod.canDeactivate && (
                  <button
                    className={`btn btn-outline-danger danger-color`}
                    onClick={() => this.props.onDeactivateClicked()}
                  >
                    Deactivate
                  </button>
                )}
                {!verificationMethod.primary && (
                  <button
                    className="btn btn-outline-primary primary-color"
                    onClick={() => this.props.onMakeDefaultClicked()}
                  >
                    Set Default
                  </button>
                )}
              </div>
            ) : (
              <button
                className="btn btn-outline-primary primary-color"
                onClick={() =>
                  phoneNumber.includes(null) && method === "sms_twilio"
                    ? this.setState({
                        showConfirmModal: true,
                      })
                    : this.props.onActivateClicked()
                }
              >
                Activate
              </button>
            )}
          </div>
          {/* End : Buttons Column */}

        </div>

        <ConfirmModal
          show={showConfirmModal}
          title={`Add Phone Number`}
          message={`Please add a phone number to activate SMS Verification.`}
          confirmButtonText="Add Phone Number"
          cancelButtonText="Cancel"
          buttonClassName="primary"
          onHide={() => this.setState({ showConfirmModal: false })}
          onConfirm={() => this.props.history.push("/account")}
        />

      </>
    );
  }

  render() {
    return this._renderMethods();
  }
}

export default withRouter(VerificationMethod);
