import React from 'react'
import General from '../../../../utils/General'

export default class UserCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: props.user,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    let { user } = this.state
    if (!user) {
      return null
    }
    return (
      <div className="d-flex align-items-center">
        <div className="symbol symbol-60px symbol-2by2 me-4">
          <div className="symbol-label fs-2 fw-bold text-white bg-success">
            {General.getInitials(user.first_name + ' ' + user.last_name)}
          </div>
        </div>
        <div className="d-flex justify-content-start flex-column">
          <a
            href="#"
            className="text-dark fw-bolder text-hover-primary mb-1 fs-6"
          >
            {user.first_name} {user.last_name}
          </a>
          <span className="text-muted fw-bold text-muted d-block fs-7">
            {user.email}
          </span>
        </div>
      </div>
    )
  }
}
