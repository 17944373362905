import React from 'react'

import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Notify from '../utils/Notify'
export default class SiteControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: [],
      isLoading: true,
    }
    this._loadInvestorSections()
  }

  _loadInvestorSections() {
    this.setState({ isLoading: true })
    Backend.getInvestorSections()
      .then((sections) => {
        this.setState({ sections, isLoading: false })
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  _updateData(key, value, index) {
    let { sections } = this.state
    sections[index][key] = value
    this.setState({ sections })
  }

  _updateSections() {
    let { sections } = this.state
    let data = {
      investor_sections: sections,
    }
    this.setState({ isSaving: true })
    Backend.updateSections(data)
      .then((res) => {
        this.setState({ isSaving: false })
        Notify.success('Sections updated successfully')
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false, isSaving: false })
      })
  }

  _renderRows() {
    let { sections, isLoading } = this.state
    if (isLoading) {
      return this._renderLoading()
    }
    return sections.map((section, index) => {
      return this._renderRow(section, index)
    })
  }

  _renderRow(section, index) {
    return (
      <div className="row mb-6 align-items-center">
        <label className="col-lg-3 col-form-label fw-bold fs-6">
          {General.snakeCaseToTitleCase(section.key)}:
        </label>
        <div className="col-lg-9">
          <div className="row">
            <div
              onClick={() =>
                this._updateData('visible', !section.visible, index)
              }
              className="form-check form-switch form-check-custom form-check-solid"
            >
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="allowchanges"
                checked={section.visible}
              />
              <label
                className="form-check-label fw-bold text-gray-400 ms-3"
                for="allowchanges"
              >
                {section.visible ? 'Visible' : 'Hidden'}
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderLoading() {
    if (!this.state.isLoading) return null
    return (
      <div class="container">
        <div class="text-center mb-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let { isLoading } = this.state
    return (
      <div id="kt_content_container" className="container-xxl">
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Site Control</h3>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <form
              id="kt_account_profile_details_form"
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
              noValidate="novalidate"
            >
              <div className="card-body border-top p-9">
                {this._renderRows()}
              </div>
            </form>
            <input type="hidden" />
            <div />
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="button"
                onClick={() => {
                  this.props.history.push('/')
                }}
                className="btn btn-light btn-active-light-primary me-2"
              >
                Discard
              </button>
              <button
                type="button"
                disabled={isLoading}
                className="btn btn-primary"
                id="kt_account_profile_details_submit"
                onClick={() => this._updateSections()}
              >
                {this.state.isSaving ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
            <input type="hidden" />
            <div />
          </div>
        </div>
      </div>
    )
  }
}
