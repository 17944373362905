import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'

export default class Backend {
  static getAllYearlyReports(category, group_by_year) {
    let endpoint =
      window.Api.Reports + '?category=' + category + '&all_years=True'
    if (group_by_year) {
      endpoint += `&group_by_year=${new Date().getFullYear() - 3}`
    }
    return FetchHelper.get(endpoint, false)
  }

  static createLoginHash() {
    return FetchHelper.post(`${window.Api.Hashes}`, { type: 'login' })
  }
  static updateCreditRatings(data) {
    return FetchHelper.put(window.Api.CreditRatings, data)
  }
  static getCreditRatings() {
    return FetchHelper.get(window.Api.CreditRatings)
  }
  static getInvestorSections() {
    return FetchHelper.get(`${window.Api.InvestorSections}`)
  }
  static updateSections(sections) {
    return FetchHelper.put(`${window.Api.InvestorSections}`, sections)
  }
  static updateDownloadCount(slug) {
    return FetchHelper.get(`${window.Api.Reports}/${slug}/download`)
  }
  static getMonthlySignups() {
    return FetchHelper.get(`${window.Api.Stats}/monthly_signups`)
  }
  static getSignUpStats() {
    return FetchHelper.get(`${window.Api.Stats}?category=users`)
  }
  static getReportStats() {
    return FetchHelper.get(`${window.Api.Stats}?category=reports`)
  }
  static updateSustainabilityReport(data, mode) {
    if (mode === 'add') {
      return FetchHelper.post(`${window.Api.Reports}`, data)
    } else {
      return FetchHelper.patch(`${window.Api.Reports}/${data.slug}`, data)
    }
  }

  static submitFile(data) {
    return FetchHelper.post(window.Api.Reports, data)
  }

  static updateAdmin(data) {
    return FetchHelper.patch(
      `${window.Api.Admin}/${AuthManager.currentUser.user.id}`,
      data
    )
  }

  static updateAdminUser(user, mode) {
    if (mode === 'add') {
      return FetchHelper.post(`${window.Api.Admin}`, user)
    }
    if (mode === 'edit') {
      delete user.email
      delete user.password
    }
    return FetchHelper.patch(`${window.Api.Admin}/${user.id}`, user)
  }

  static submitReport(data, mode) {
    if (mode === 'add') {
      return FetchHelper.post(window.Api.Reports, data)
    } else {
      return FetchHelper.patch(`${window.Api.Reports}/${data.slug}`, data)
    }
  }

  static deleteReport(data) {
    return FetchHelper.delete(`${window.Api.Reports}/${data.slug}`)
  }

  static submitCreditRating(data, mode) {
    if (mode === 'add') {
      return FetchHelper.post(window.Api.CreditRatings, data)
    } else {
      return FetchHelper.patch(`${window.Api.CreditRatings}/${data.id}`, data)
    }
  }

  static submitUser(user, mode) {
    if (mode === 'add') {
      return FetchHelper.post(window.Api.Investors, user)
    } else {
      return FetchHelper.patch(`${window.Api.Investors}/${user.id}`, user)
    }
  }

  static deleteUser(user) {
    let data = {
      status: 'banned',
    }
    return FetchHelper.patch(`${window.Api.Investors}/${user.id}`, data)
  }

  static submitEmail(data) {
    return FetchHelper.post(window.Api.Mail, data)
  }

  static getFinancialStats() {
    return FetchHelper.get(window.Api.FinancialStats)
  }

  static saveFinancialStats(data) {
    return FetchHelper.post(window.Api.FinancialStats, data)
  }

  static updateResults(data, mode) {
    if (mode === 'add') {
      return FetchHelper.post(window.Api.UpcomingResults, data)
    } else {
      return FetchHelper.patch(window.Api.UpcomingResults + '/' + data.id, data)
    }
  }

  static getUpcomingResults() {
    return FetchHelper.get(window.Api.UpcomingResults + '?future=True')
  }

  static getFiles() {
    return FetchHelper.get(`${window.Api.Files}`)
  }

  static updateUserAccount(currentUser) {
    let data = { ...currentUser }
    return FetchHelper.patch(`${window.Api.Admin}/${currentUser.user.id}`, data)
  }

  static uploadImage(file) {
    let data = new FormData()
    data.append('image', file, file.name)
    data.append('settings.color', '#000000')
    return FetchHelper.post(window.Api.Images, data, true)
  }

  static uploadFile(file) {
    let data = new FormData()
    data.append('file', file, file.name)
    return FetchHelper.post(window.Api.Files, data, true)
  }

  static resubscribeInvestors(data) {
    return FetchHelper.post(window.Api.ResubscribeUsers, data)
  }

  static submitOrUpdateReport(data, mode) {
    if (mode === 'add') {
      return FetchHelper.post(window.Api.Reports, data)
    } else {
      return FetchHelper.patch(`${window.Api.Reports}/${data.old_slug}`, data)
    }
  }

  static getReportBySlug(slug) {
    return FetchHelper.get(`${window.Api.Reports}/${slug}`)
  }

  static getHomePageSliderNumber() {
      return FetchHelper.get(window.Api.HomeSlidesNumber)
  }
}
