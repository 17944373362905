import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'

import Dropzone from '../common/CustomDropzone'
import TextEditor from '../common/TextEditor'
import FlatPickr from 'react-flatpickr'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import TextFormat from '../../../utils/TextFormat'

import moment from 'moment-timezone'
import DateTime from '../../../utils/DateTime'
const timezone = 'America/New_York'
export default class ResultsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      data: props.data,
      show: props.show,
      mode: props.mode,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _updateData(key, value) {
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data })
  }

  _isFormValid() {
    let { data } = this.state

    let error = null
    if (!data.due_date) {
      error = 'Please enter a valid date'
    } else if (!data.issue_at) {
      error = 'Please enter a issue date'
    } else if (!data.title) {
      error = 'Please enter a valid title'
    } else if (!data.description) {
      error = 'Please enter a valid description'
    }
    if (error) {
      Notify.error(error.message)
      return false
    }

    return true
  }

  _updateResults() {
    let { data } = this.state
    if (!this._isFormValid()) {
      return
    }
    this.setState({ isLoading: true })
    Backend.updateResults(data, this.props.mode)
      .then((res) => {
        this.setState({
          isLoading: false,
        })
        Notify.success('Events have been updated successfully.')
        this.props.onUpdated(res)
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { data, show, isLoading } = this.state
    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>
              {TextFormat.capitalizeFirst(this.props.mode)} Upcoming Event
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <form id="upcoming-result-form" className="form" action="#">
                <div id="upcoming-result-form-inputs">
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                      <span className="required">Title</span>
                      <i
                        className="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        title
                        data-bs-original-title="What is the name of the upcoming event"
                        aria-label="What is the name of the upcoming event"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="Q3 Financing Results"
                      name="result_name"
                      value={data?.title}
                      onChange={(e) => {
                        this._updateData('title', e.target.value)
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="required fs-6 fw-bold form-label mb-2">
                      Description
                    </label>
                    <textarea
                      className="form-control form-control-solid"
                      placeholder
                      name="sustainability_desc"
                      value={data?.description}
                      onChange={(e) =>
                        this._updateData('description', e.target.value)
                      }
                    />
                  </div>
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="required fs-6 fw-bold form-label mb-2">
                      Due Date {DateTime.getTimezoneAbbreviation(timezone)}
                    </label>

                    <div className="position-relative">
                      <FlatPickr
                        className="form-control"
                        value={moment(data?.due_date).tz(timezone).format()}
                        options={{
                          enableTime: true,
                          noCalendar: false,
                          altInput: true,
                          dateFormat: 'Y-m-d H:i',
                          static: true,
                        }}
                        onChange={(dates) => {
                          let date = moment(dates[0]).tz(timezone, true)
                          let utc = moment.utc(date).format()
                          this._updateData('due_date', utc)
                        }}
                      />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                        <span className="svg-icon svg-icon-muted">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={21}
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                              fill="black"
                            />
                            <path
                              d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="required fs-6 fw-bold form-label mb-2">
                      Issue Date/Time{' '}
                      {DateTime.getTimezoneAbbreviation(timezone)}
                    </label>

                    <div className="position-relative">
                      <FlatPickr
                        className="form-control"
                        value={moment(data?.issue_at).tz(timezone).format()}
                        options={{
                          enableTime: true,
                          noCalendar: false,
                          altInput: true,
                          dateFormat: 'Y-m-d H:i',
                          static: true,
                        }}
                        onChange={(dates) => {
                          let date = moment(dates[0]).tz(timezone, true)
                          let utc = moment.utc(date).format()
                          this._updateData('issue_at', utc)
                        }}
                      />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                        <span className="svg-icon svg-icon-muted">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={21}
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                              fill="black"
                            />
                            <path
                              d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="required fs-6 fw-bold form-label mb-2">
                      Upload Document
                    </label>

                    <div className="position-relative">
                      <Dropzone
                        type="application/pdf"
                        subtitle="Upload a .PDF"
                        multi={false}
                        onUpload={(file) => {
                          this.setState({ fileUploading: false })
                          this._updateData('file_url', file.url)
                        }}
                        isUploading={(value) =>
                          this.setState({ fileUploading: value })
                        }
                        onRemove={() => {
                          this.setState({ fileUploading: false })
                          delete data.file_url
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center pt-15">
                  <button
                    type="button"
                    onClick={() => this.props.onHide()}
                    className="btn btn-light me-3"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    id="kt_modal_upcoming_result_submit"
                    className="btn btn-primary"
                    onClick={() => this._updateResults()}
                  >
                    {isLoading ? 'Please wait...' : 'Save Changes'}
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
