import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import runtimeEnv from '@mars/heroku-js-runtime-env'

import AuthManager from '../../utils/AuthManager'
import Backend from '../../utils/Backend'
import Event from '../../utils/Event'

const env = runtimeEnv()
const INVESTOR_URL = env.REACT_APP_INVESTOR_URL
export class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser?.user,
    }
  }

  componentDidMount() {
    Event.on('USER_UPDATED', () =>
      this.setState({
        currentUser: AuthManager.currentUser?.user,
      })
    )
  }

  componentWillUnmount() {
    Event.off('USER_UPDATED')
  }

  _onViewInvestorPageClicked() {
    this.setState({ loadingHash: true })
    return Backend.createLoginHash()
      .then((hash) => {
        window.open(INVESTOR_URL + '?h=' + hash.value, '_blank').focus()
        this.setState({ loadingHash: false })
      })
      .catch((error) => {
        window.open(INVESTOR_URL, '_blank').focus()
        this.setState({ loadingHash: false })
      })
  }

  _logOut() {
    AuthManager.logout()
  }

  render() {
    return (
      <div id="kt_header" style={{}} className="header align-items-stretch">
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            >
              <span className="svg-icon svg-icon-2x mt-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <a href="javascript:;" className="d-lg-none">
              <img
                alt="Logo"
                src="assets/media/logos/avolon-logo.png"
                className="h-30px"
              />
            </a>
          </div>
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <div className="d-flex align-items-stretch" id="kt_header_nav">
              <div
                className="header-menu align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
              >
                <div className="fs-3 fw-bolder text-gray-800 my-6">
                  Welcome Back, {AuthManager.currentUser.user.first_name}!
                </div>
              </div>
            </div>
            <div className="d-flex align-items-stretch flex-shrink-0">
              <div className="d-flex align-items-stretch flex-shrink-0">
                <div className="d-flex align-items-center ms-1 ms-lg-3">
                  <a
                    onClick={() => {
                      this._onViewInvestorPageClicked()
                    }}
                    className="btn btn-success"
                    target="_blank"
                  >
                    View Investor Page
                  </a>
                </div>

                <div
                  className="d-flex align-items-center ms-1 ms-lg-3"
                  id="kt_header_user_menu_toggle"
                >
                  <div
                    className="cursor-pointer symbol symbol-30px symbol-md-40px"
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <img
                      src={require('../../assets/media/profile.jpeg')}
                      alt="user"
                    />
                  </div>

                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                    data-kt-menu="true"
                  >
                    <div className="menu-item px-3">
                      <div className="menu-content d-flex align-items-center px-3">
                        <div className="d-flex flex-column">
                          <div className="fw-bolder d-flex align-items-center fs-5">
                            Admin
                          </div>
                          <a
                            href="#"
                            className="fw-bold text-muted text-hover-primary fs-7"
                          >
                            {AuthManager.currentUser.user.email}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="separator my-2" />
                    <div className="menu-item px-5 my-1">
                      <a href="/account" className="menu-link px-5">
                        Account Settings
                      </a>
                    </div>
                    <div className="menu-item px-5 my-1">
                      <a href="/admin-users" className="menu-link px-5">
                        Manage Admin Accounts
                      </a>
                    </div>
                    <div className="menu-item px-5">
                      <a
                        onClick={() => this._logOut()}
                        className="menu-link px-5"
                      >
                        Sign Out
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Header)
