import React, { Component } from 'react'

import General from '../../../utils/General'

import Chart from 'react-apexcharts'

export default class LineChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: props.categories,
      data: props.data,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    let { data, categories } = this.state

    let options = {
      theme: {
        mode: 'light',
        monochrome: {
          enabled: true,
          color: '#36db6f',
        },
      },
      stroke: {
        curve: 'smooth',
      },
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        show: false,
        type: 'text',
        categories: categories,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
      },
    }

    return (
      <Chart
        options={options}
        series={[
          {
            name: '',
            data: data,
          },
        ]}
        type="area"
      />
    )
  }
}

LineChart.defaultProps = {}

const styles = {
  container: {
    width: 100,
    height: 50,
  },
  canvas: {
    display: 'block',
    width: 100,
    height: 50,
  },
}
