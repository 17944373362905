import React from 'react'

import Modal from 'react-bootstrap/Modal'

export default class InputModal extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      title,
      isLoading,
      buttonClassName,
      confirmButtonText,
      cancelButtonText,
    } = this.props

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">{this.props.label}</span>
            </label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder={this.props.placeholder}
              value={this.state?.value}
              name="value"
              onChange={(e) => {
                this.setState({ value: e.target.value })
              }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={isLoading}
            onClick={() => this.props.onHide()}
          >
            {cancelButtonText}
          </button>

          <button
            type="button"
            className={`btn btn-${buttonClassName}`}
            disabled={isLoading}
            onClick={() => this.props.onConfirm(this.state.value)}
          >
            {confirmButtonText}
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

InputModal.defaultProps = {
  title: 'Input Modal',
  loading: false,
  buttonClassName: 'primary',
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
}
