import React from "react";

import PressReleaseTable from "./components/tables/PressReleaseTable";

export default class PressRelease extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showUpcomingResultModal: false,
    };
  }

  _renderReportedResultsTable() {
    return <PressReleaseTable title={"Avolon Press Release"} />;
  }

  render() {
    return (
      <div className="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" className="container-xxl">
          {this._renderReportedResultsTable()}
        </div>
      </div>
    );
  }
}
