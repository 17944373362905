import React from "react";

import BaseTable from "./BaseTable";

import moment from "moment";

import UserCell from "./cells/UserCell";
import EditCell from "./cells/EditCell";

import AdminUserModal from "../modals/AdminUserModal";
import AuthManager from "../../../utils/AuthManager";

export default class AdminUsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      superuser: AuthManager.currentUser.role === "superuser",
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh());
  }

  _refresh() {
    this.table.current.refresh();
  }

  _getColumns() {
    const { superuser } = this.state;

    return [
      {
        Header: "USER",
        Cell: (rowInfo) => {
          return <UserCell user={rowInfo.original.user} />;
        },
        width: 420,
        sortable: false,
      },
      {
        Header: "LAST LOGIN",
        id: "user.last_login",
        sortable: false,
        accessor: (row) => {
          if (!row.user.last_login) {
            return "No login";
          }
          return moment(row.user.last_login).format("DD MMM YYYY");
        },
        type: "date",
        format: "%d %b %Y",
      },
      {
        Header: "JOINED DATE",
        id: "user.created_at",
        sortable: false,
        accessor: (row) => {
          return moment(row.user.created_at).format("DD MMM YYYY");
        },
        type: "date",
        format: "%d %b %Y",
      },
      {
        id: "active",
        Header: "",
        sortable: false,
        width: 100,
        exportable: false,
        Cell: (rowInfo) => {
          return (
            superuser && (
              <EditCell
                item={rowInfo.original}
                onClick={() => {
                  this.setState({
                    showUserModal: true,
                    selectedItem: rowInfo.original,
                  });
                }}
              />
            )
          );
        },
      },
    ];
  }

  _renderToolbar() {
    return (
      <div className="card-toolbar">
        <button
          type="button"
          className="btn btn-primary my-5"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_add_admin"
          onClick={() => this.setState({ showUserModal: true })}
        >
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          Add User
        </button>
      </div>
    );
  }

  render() {
    const { superuser } = this.state;

    const columns = this._getColumns();

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Admin}
          title={"Admin Users"}
          showFilter={false}
          className="card mb-5 mb-xl-8"
          noDataMessage={"No users found"}
          renderToolbar={() => superuser && this._renderToolbar()}
          columns={columns}
          showExport={false}
          showPagination={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
        />
        <AdminUserModal
          mode={this.state.selectedItem ? "edit" : "add"}
          data={this.state.selectedItem?.user}
          show={this.state.showUserModal}
          onHide={() =>
            this.setState({ showUserModal: false, selectedItem: null })
          }
          onUpdated={() =>
            this.setState({ showUserModal: false, selectedItem: null }, () =>
              this._refresh()
            )
          }
        />
      </div>
    );
  }
}

AdminUsersTable.defaultProps = {};
