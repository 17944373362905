import React from 'react'

import Backend from '../utils/Backend'
import Currency from '../utils/Currency'
import Notify from '../utils/Notify'

const DEFAULT_DATA = [
  {
    type: 'secured_debt',
  },
  {
    type: 'unsecured_debt',
  },
  {
    type: 'less',
  },
]
export default class OutstandingDebt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      isLoading: true,
    }
    this._getOutstandingDebt()
  }

  _getOutstandingDebt() {
    this.setState({ isLoading: true })
    Backend.getFinancialStats()
      .then((res) => {
        if (res.length === 0) {
          res = DEFAULT_DATA
        }
        this.setState({
          data: res,
          isLoading: false,
        })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
      })
  }

  _isValid() {
    const { data } = this.state

    let error = null

    if (!data) {
      error = 'No data to submit'
    }
    data.forEach((item) => {
      if (!item.hasOwnProperty('amount')) {
        error = 'Amount is required for all rows'
      }
      if (!item.hasOwnProperty('name')) {
        error = 'Name is required for all rows'
      }
    })

    if (error) {
      Notify.error(error.message)
      return false
    }

    return true
  }

  _submit() {
    let { data } = this.state
    if (!this._isValid()) {
      return
    }
    let submitData = {
      financial_stats: data,
    }
    Backend.saveFinancialStats(submitData)
      .then(() => {
        Notify.success('Outstanding Debt Successfully Updated')
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  _updateRow(index, value, key) {
    let { data } = this.state
    data[index][key] = value
    this.setState({ data })
  }

  _deleteRow(index) {
    let { data } = this.state
    data.splice(index, 1)
    this.setState({ data })
  }

  _addNewRow(type) {
    let { data } = this.state
    data.push({
      type,
    })
    this.setState({ data })
  }

  _getSectionTotal(arr, key, type) {
    let total = 0
    arr.forEach((item) => {
      if (item.hasOwnProperty(key)) {
        if (item.type === type) {
          total += item[key]
        }
      }
    })
    return total
  }

  _renderLoading() {
    if (!this.state.isLoading) return null
    return (
      <div class="container">
        <div class="text-center mb-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  _renderRows(type) {
    let { data } = this.state
    return data.map((item, index) => {
      if (item.type !== type) {
        return null
      }
      return this._renderRow(item, index)
    })
  }

  _renderRow(item, index) {
    return (
      <div key={index} data-repeater-item className="form-group row mb-5">
        <div className="col-md-5">
          <label className="form-label">Debt Name:</label>
          <input
            type="text"
            className="form-control mb-2 mb-md-0"
            placeholder="E.g. Senior Notes"
            value={item?.name}
            onChange={(e) => {
              this._updateRow(index, e.target.value, 'name')
            }}
          />
        </div>
        <div className="col-md-3">
          <label className="form-label">Amount ($):</label>
          <input
            type="number"
            className="form-control"
            placeholder="0.00"
            name="price"
            value={item.amount ? item.amount / 100 : ''}
            onChange={(e) => {
              let amount = e.target.value
                ? Math.round(parseFloat(e.target.value).toFixed(2) * 100)
                : null

              this._updateRow(index, amount, 'amount')
            }}
          />
        </div>
        <div className="col-md-4">
          <a
            onClick={() => this._deleteRow(index)}
            data-repeater-delete
            className="btn btn-sm btn-light-danger mt-3 mt-md-9"
          >
            <i className="la la-trash-o fs-3" />
            Delete Row
          </a>
        </div>
      </div>
    )
  }

  _renderSection(title, type) {
    let { data, isLoading } = this.state
    if (isLoading) {
      return null
    }
    let total = this._getSectionTotal(data, 'amount', type)
    return (
      <div className="col-lg-12">
        <div className="row bg-secondary">
          <div className="col-md-6">
            <label className="form-label fs-3 py-5 px-3">{title}</label>
          </div>
          <div className="col-md-6">
            <label className="form-label fs-3 py-5 px-5 w-100 text-right">
              {Currency.format(total)}
            </label>
          </div>
        </div>
        <div
          id="kt_docs_repeater_nested"
          className="border border-dashed p-5 mb-5"
        >
          <div className="form-group">
            <div data-repeater-list="kt_docs_repeater_nested_outer">
              {this._renderRows(type)}
            </div>
          </div>
          <div className="form-group">
            <a
              onClick={() => this._addNewRow(type)}
              data-repeater-create
              className="btn btn-sm btn-light-primary"
            >
              <i className="la la-plus" />
              Add Row
            </a>
          </div>
        </div>
      </div>
    )
  }

  _renderTotalSection() {
    let { data, isLoading } = this.state
    if (isLoading) {
      return null
    }
    let total = 0
    let unsecured_debt = 0
    let secured_debt = 0
    let less = 0
    data.forEach((item) => {
      if (item.hasOwnProperty('amount')) {
        if (item.type === 'unsecured_debt') {
          unsecured_debt += item.amount
        } else if (item.type === 'secured_debt') {
          secured_debt += item.amount
        } else if (item.type === 'less') {
          less += item.amount
        }
      }
    })
    total = unsecured_debt + secured_debt - less
    return (
      <div className="row bg-light-primary border border-primary">
        <div className="col-md-6">
          <label className="form-label fs-3 py-5 px-3">Total</label>
        </div>
        <div className="col-md-6">
          <label className="form-label fs-3 py-5 px-5 w-100 text-right">
            {Currency.format(total)}
          </label>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className="post d-flex flex-column-fluid" id="kt_post">
          {/*begin::Container*/}
          <div id="kt_content_container" className="container-xxl">
            <div className="card mb-5 mb-xl-10">
              {/*begin::Card header*/}
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
              >
                {/*begin::Card title*/}
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Outstanding Debt</h3>
                </div>
                {/*end::Card title*/}
              </div>
              {/*begin::Card header*/}

              {/*begin::Content*/}
              <div
                id="kt_account_settings_profile_details"
                className="collapse show"
              >
                {/*begin::Form*/}
                <form
                  id="kt_account_profile_details_form"
                  className="form fv-plugins-bootstrap5 fv-plugins-framework"
                  noValidate="novalidate"
                >
                  {/*begin::Card body*/}
                  <div className="card-body border-top p-9">
                    <div className="row mb-6">
                      {this._renderSection('Unsecured Debt', 'unsecured_debt')}
                      {this._renderSection('Secured Debt', 'secured_debt')}
                      {this._renderSection('Less', 'less')}
                    </div>
                    {this._renderTotalSection()}
                  </div>
                  {this._renderLoading()}
                  {/*end::Card body*/}
                  {/*begin::Actions*/}
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                    >
                      Discard
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="kt_account_profile_details_submit"
                      onClick={() => this._submit()}
                    >
                      Save Changes
                    </button>
                  </div>
                  {/*end::Actions*/}
                  <input type="hidden" />
                  <div />
                </form>
                {/*end::Form*/}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
