import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import TextFormat from '../../../utils/TextFormat'
export default class EmailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: props.mode,
      data: props.data,
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _updateData(key, value) {
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data })
  }

  _isFormValid() {
    let { data, mode } = this.state

    let error = null

    if (!data.first_name) {
      error = 'First name is required.'
    }
    if (!data.last_name) {
      error = 'Last name is required.'
    }
    if (!data.email) {
      error = 'Email is required.'
    }
    if (!data.company) {
      error = 'Company is required.'
    }
    if (!data.job_title) {
      error = 'Job title is required.'
    }
    if (!data.password && mode === 'add') {
      error = 'Password is required.'
    }
    if (!data.confirm_password && mode === 'add') {
      error = 'Confirm password is required.'
    }
    if (data.password !== data.confirm_password && mode === 'add') {
      error = 'Password and confirm password must match.'
    }

    if (error) {
      Notify.error(error.message)
      return false
    }

    return true
  }

  _handleUser() {
    let { data, mode } = this.state

    if (!this._isFormValid()) {
      return
    }
    if (data.user) {
      delete data.user
    }
    if (data.email && mode === 'edit') {
      delete data.email
    }
    if (mode === 'add') {
      data.role = 'investor'
    }
    this.setState({ isLoading: true })
    Backend.submitUser(data, mode)
      .then(() => {
        this.setState({ isLoading: false })
        let message =
          mode === 'add'
            ? 'User has been added successfully.'
            : 'User has been updated successfully.'
        Notify.success(message)
        this.props.onUpdated()
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        Notify.error(error.message)
      })
  }

  render() {
    const { data, show, isLoading, mode } = this.state
    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>
              {TextFormat.capitalizeFirst(this.state.mode)} User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="add-user-form" className="form" action="#">
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">First Name</span>
                </label>
                <input
                  className="form-control form-control-solid"
                  type="text"
                  placeholder="First Name"
                  name="name"
                  value={data?.first_name}
                  onChange={(e) => {
                    this._updateData('first_name', e.target.value)
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Last Name</span>
                </label>
                <input
                  className="form-control form-control-solid"
                  type="text"
                  placeholder="Last Name"
                  name="name"
                  value={data?.last_name}
                  onChange={(e) => {
                    this._updateData('last_name', e.target.value)
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Job Title</span>
                </label>
                <input
                  className="form-control form-control-solid"
                  type="text"
                  placeholder="Job Title"
                  name="job"
                  value={data?.job_title}
                  onChange={(e) =>
                    this._updateData('job_title', e.target.value)
                  }
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Company</span>
                </label>
                <input
                  className="form-control form-control-solid"
                  type="text"
                  placeholder="Company"
                  name="company"
                  value={data?.company}
                  onChange={(e) => this._updateData('company', e.target.value)}
                />
              </div>
              {mode == 'add' && (
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Email Address</span>
                  </label>

                  <input
                    className="form-control form-control-solid"
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={data?.email}
                    autocomplete="off"
                    onChange={(e) => this._updateData('email', e.target.value)}
                  />
                </div>
              )}
              {mode == 'add' && (
                <>
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                      <span className="required">Password</span>
                    </label>

                    <input
                      type="password"
                      className="form-control form-control-solid"
                      placeholder="Password"
                      name="password"
                      value={data?.password}
                      onChange={(e) =>
                        this._updateData('password', e.target.value)
                      }
                    />
                  </div>
                  <div className="d-flex flex-column mb-7 fv-row">
                    <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                      <span className="required">Confirm Password</span>
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-solid"
                      placeholder="Confirm Password"
                      name="confirm_user_password"
                      value={data?.confirm_password}
                      onChange={(e) =>
                        this._updateData('confirm_password', e.target.value)
                      }
                    />
                  </div>
                </>
              )}
              <div className="text-center pt-15">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  id="upload-success"
                  disabled={isLoading}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => this._handleUser()}
                >
                  <span className="indicator-label">
                    {isLoading
                      ? 'Please wait...'
                      : `${mode === 'add' ? 'Add' : 'Update'} User`}
                  </span>
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
