import React from 'react'
import RecentSignUpsTable from './components/tables/RecentSignUpsTable'
import UpcomingResultsCard from './components/cards/UpcomingResultsCard'
import PopularReports from './components/tables/PopularReports'
import LineChart from './components/charts/LineChart'

import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'

const LAST_24_HOURS = 'Last 24 Hours'
const LAST_7_DAYS = 'Last 7 Days'
const LAST_30_DAYS = 'Last 30 Days'
const LAST_YEAR = 'YTD'
const TOTAL = 'Total'
export default class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showUpcomingResultModal: false,
      reportsStatsLoading: true,
      signUpsLoading: true,
    }
    this._loadReportStats()
    this._loadSignUpStats()
    this._loadMonthlyStats()
  }

  _loadMonthlyStats() {
    this.setState({ monthlyStatsLoading: true })
    Backend.getMonthlySignups()
      .then((response) => {
        this._formatChartData(response)
        this.setState({
          monthlyStatsLoading: false,
          monthlyStats: response,
        })
      })
      .catch((err) => {
        this.setState({ monthlyStatsLoading: false })
      })
  }

  _formatChartData(data) {
    let signUpCategories = []
    let signUpData = []
    data.forEach((item) => {
      for (const [key, value] of Object.entries(item)) {
        signUpCategories.push(key)
        signUpData.push(value)
      }
    })
    this.setState({ signUpCategories, signUpData })
  }

  _loadReportStats() {
    this.setState({ reportsStatsLoading: true })
    Backend.getReportStats()
      .then((response) => {
        this.setState({
          reportsStatsLoading: false,
          downloadCount: response.day_count,
          selectedDownloadStat: 0,
          reportStats: [
            { label: LAST_24_HOURS, value: 0, count: response.day_count },
            { label: LAST_7_DAYS, value: 1, count: response.week_count },
            { label: LAST_30_DAYS, value: 2, count: response.month_count },
            { label: LAST_YEAR, value: 3, count: response.year_count },
            { label: TOTAL, value: 4, count: response.total },
          ],
        })
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ reportsStatsLoading: false })
      })
  }

  _loadSignUpStats() {
    this.setState({ signUpsLoading: true })
    Backend.getSignUpStats()
      .then((response) => {
        this.setState({
          signUpsLoading: false,
          signUpCount: response.day_count,
          signUpStats: [
            { label: LAST_24_HOURS, value: 0, count: response.day_count },
            { label: LAST_7_DAYS, value: 1, count: response.week_count },
            { label: LAST_30_DAYS, value: 2, count: response.month_count },
            { label: LAST_YEAR, value: 3, count: response.year_count },
            { label: TOTAL, value: 4, count: response.total },
          ],
        })
      })
      .catch((err) => {
        this.setState({ signUpsLoading: false })
      })
  }

  _getSignTotal() {
    let { signUpData } = this.state
    let total = 0
    if (!signUpData) {
      return total
    }
    signUpData.forEach((item) => {
      total += item
    })
    return total
  }

  _renderSignUpsOptions() {
    let { signUpStats, selectedSignUpStat } = this.state
    if (!signUpStats) {
      return null
    }
    return signUpStats.map((item, index) => {
      return (
        <option
          key={index}
          value={item.value}
          selected={selectedSignUpStat === item.value}
        >
          {item.label}
        </option>
      )
    })
  }

  _renderDownloadsOptions() {
    let { reportStats, selectedDownloadStat } = this.state
    if (!reportStats) {
      return null
    }
    return reportStats.map((item, index) => {
      return (
        <option
          value={item.value}
          selected={selectedDownloadStat === item.value}
        >
          {item.label}
        </option>
      )
    })
  }

  _renderLoader(loading) {
    if (!loading) {
      return null
    }
    return (
      <div class="container">
        <div class="text-center mt-5">
          <div class="spinner-border text-white" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let { reportStats, signUpStats, reportsStatsLoading, signUpsLoading } =
      this.state
    return (
      <>
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <div className="row g-6 g-xl-5">
              <div className="col-md-12 col-xl-12">
                <div className="card card-xl-stretch">
                  <div className="card-header border-0 pt-9">
                    <div className="card-title m-0">
                      <p className="text-gray-400 fw-bold fs-3 mt-1 mb-7">
                        Here's what you missed...
                      </p>
                    </div>
                  </div>

                  <div className="card-body p-5">
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <a
                              href="#"
                              className="card hoverable border rounded bg-success mb-5"
                            >
                              {/*begin::Body*/}
                              <div className="card-body">
                                {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm008.svg*/}
                                <span className="svg-icon svg-icon-white svg-icon-2x ms-n1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                                      fill="black"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x={8}
                                      y={3}
                                      width={8}
                                      height={8}
                                      rx={4}
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                <div
                                  className="text-white fw-bolder fs-2 mb-2 mt-5 counted"
                                  data-kt-countup="true"
                                  data-kt-countup-value={100}
                                  data-kt-countup-prefix="+"
                                >
                                  {this.state.signUpCount || 0}
                                </div>
                                <div className="fw-bold text-white">
                                  New Customers <br />
                                  {signUpsLoading &&
                                    this._renderLoader(signUpsLoading)}
                                  {!signUpsLoading && (
                                    <select
                                      className="form-control"
                                      value={this.state.selectedCustomerValue}
                                      onChange={(e) => {
                                        let signUpCount = signUpStats.filter(
                                          (item) =>
                                            item.value ===
                                            parseInt(e.target.value)
                                        )[0].count
                                        this.setState({
                                          signUpCount,
                                          selectedSignUpStat: e.target.value,
                                        })
                                      }}
                                    >
                                      {this._renderSignUpsOptions()}
                                    </select>
                                  )}
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a
                              href="#"
                              className="card hoverable mb-xl-8 border-dashed rounded bg-primary mb-5"
                            >
                              <div className="card-body">
                                <span className="svg-icon svg-icon-white svg-icon-2x ms-n1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z"
                                      fill="black"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                <div
                                  className="text-white fw-bolder fs-2 mb-2 mt-5 counted"
                                  data-kt-countup="true"
                                  data-kt-countup-value={3000}
                                  data-kt-countup-prefix="+"
                                >
                                  {this.state.downloadCount || 0}
                                </div>
                                <div className="fw-bold text-white">
                                  File Downloads <br />
                                  {reportsStatsLoading &&
                                    this._renderLoader(reportsStatsLoading)}
                                  {!reportsStatsLoading && (
                                    <select
                                      className="form-control"
                                      value={this.state.selectedStat}
                                      onChange={(e) => {
                                        let downloadCount = reportStats.filter(
                                          (item) =>
                                            item.value ===
                                            parseInt(e.target.value)
                                        )[0].count
                                        this.setState({
                                          downloadCount,
                                          selectedDownloadStat: e.target.value,
                                        })
                                      }}
                                    >
                                      {this._renderDownloadsOptions()}
                                    </select>
                                  )}
                                </div>
                              </div>
                            </a>
                          </div>
                          <UpcomingResultsCard />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card border mb-xl-8">
                          <div className="card-header border-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bolder fs-3 mb-1 text-primary">
                                Monthly Signups
                              </span>
                              <span className="text-muted fw-bold fs-7">
                                Total: {this._getSignTotal()}
                              </span>
                            </h3>
                            <div className="card-toolbar">
                              <button
                                type="button"
                                className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                                data-kt-menu-trigger="click"
                                data-kt-menu-placement="bottom-end"
                              >
                                <span className="svg-icon svg-icon-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x={5}
                                        y={5}
                                        width={5}
                                        height={5}
                                        rx={1}
                                        fill="#000000"
                                      />
                                      <rect
                                        x={14}
                                        y={5}
                                        width={5}
                                        height={5}
                                        rx={1}
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                      <rect
                                        x={5}
                                        y={14}
                                        width={5}
                                        height={5}
                                        rx={1}
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                      <rect
                                        x={14}
                                        y={14}
                                        width={5}
                                        height={5}
                                        rx={1}
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </button>
                              <div
                                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
                                data-kt-menu="true"
                              >
                                <div className="menu-item px-3">
                                  <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                                    Manage Investors
                                  </div>
                                </div>
                                <div className="menu-item px-3">
                                  <a
                                    href="/investors"
                                    className="menu-link px-3"
                                  >
                                    View All Users
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body d-flex flex-column">
                            <LineChart
                              categories={this.state.signUpCategories}
                              data={this.state.signUpData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-12">
                <div className="card card-xl-stretch mb-5 mb-xl-8">
                  <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="fw-bolder text-dark">
                        Latest Sign Ups
                      </span>
                      <span className="text-muted mt-1 fw-bold fs-7">
                        Most Recent 5
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <a
                        onClick={() => this.props.history.push('/investors')}
                        type="button"
                        className="btn btn-sm btn-primary btn-active-light-primary"
                      >
                        View All
                      </a>
                    </div>
                  </div>
                  <RecentSignUpsTable />
                </div>
              </div>
              <div className="col-md-12 col-xl-12">
                <div className="card card-xl-stretch mb-5 mb-xl-8">
                  <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="fw-bolder text-dark">
                        Most Downloaded Reports
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <a
                        onClick={() => this.props.history.push('/reports')}
                        type="button"
                        className="btn btn-sm btn-primary btn-active-light-primary"
                      >
                        View All
                      </a>
                    </div>
                  </div>
                  <div className="card-body pt-3">
                    <PopularReports />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
