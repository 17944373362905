import React from "react";

import BaseTable from "./BaseTable";
import UserCell from "./cells/UserCell";
import YearPicker from "../common/YearPicker";

import UsersModal from "../modals/UserModal";
import ConfirmModal from "../modals/ConfirmModal";
import ActionCell from "./cells/ActionCell";

import moment from "moment";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import DateTime from "../../../utils/DateTime";

import InvestorActivityTable from "./InvestorActivityTable";

export default class UsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: moment().format("YYYY"),
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh());
  }

  _deleteUser() {
    let { selectedUser } = this.state;
    Backend.deleteUser(selectedUser.user)
      .then(() => {
        Notify.success("User deleted successfully");
        this._refresh();
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _refresh() {
    this.table.current.refresh();
  }

  _getColumns() {
    return [
      {
        Header: "Investor",
        accessor: "user",
        Cell: (rowInfo) => {
          return <UserCell user={rowInfo.original.user} />;
        },
        width: 220,
        sortable: false,
      },
      {
        Header: "Company",
        id: "company",
        sortable: false,
        accessor: "company",
      },
      {
        Header: "Job Title",
        id: "job_title",
        sortable: false,
        accessor: "job_title",
      },
      {
        Header: "Signup Date",
        id: "user.created_at",
        sortable: false,
        accessor: (row) => moment(row.user.created_at).format("DD MMM YYYY"),
      },
      {
        Header: "Last Activity",
        id: "user.last_login",
        sortable: false,
        accessor: (row) => {
          if (!row.user.last_login) {
            return "No login";
          }
          return moment(row.user.last_login).format("DD MMM YYYY");
        },
        type: "date",
        format: "%d %b %Y",
      },
      {
        Header: "Subscribed",
        id: "user.mail_subscribed",
        sortable: false,
        accessor: (row) => {
          if (row.mail_subscribed) {
            return "Yes";
          }
          return "No";
        },
      },
      {
        id: "active",
        Header: "",
        sortable: false,
        width: 100,
        exportable: false,
        Cell: (rowInfo) => {
          return (
            <ActionCell
              item={rowInfo.original}
              onDeleteClicked={(item) => {
                this.setState({
                  showDeleteModal: true,
                  selectedUser: item,
                });
              }}
              onEditClicked={(item) => {
                this.setState({
                  showUserModal: true,
                  selectedUser: item,
                });
              }}
            />
          );
        },
      },
    ];
  }

  _renderToolbar() {
    return (
      <div className="card-toolbar">
        <button
          className="btn btn-primary my-5 me-10"
          data-bs-toggle="modal"
          data-bs-target="#add-result"
          onClick={() => {
            this.setState({ showUserModal: true });
          }}
        >
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-90 11.364 20.364)"
                fill="black"
              />
              <rect
                x="4.36396"
                y="11.364"
                width={16}
                height={2}
                rx={1}
                fill="black"
              />
            </svg>
          </span>
          <span className="btn-label">Add Investor</span>
        </button>
      </div>
    );
  }

  _renderInvestorDetails(investor) {
    return <InvestorActivityTable investor={investor} showExport={false} />;
  }

  render() {
    let { selectedDate } = this.state;
    const columns = this._getColumns();
    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Investors}
          showExport={true}
          showFilter={false}
          className="card mb-5 mb-xl-8"
          noDataMessage={"No investors found"}
          title={"Avolon Investors"}
          renderToolbar={() => this._renderToolbar()}
          SubComponent={(row) => this._renderInvestorDetails(row.original)}
          columns={columns}
          showPagination={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
        />
        <UsersModal
          mode={this.state.selectedUser ? "edit" : "add"}
          data={
            this.state.selectedUser
              ? { ...this.state.selectedUser.user, ...this.state.selectedUser }
              : null
          }
          show={this.state.showUserModal}
          onUpdated={() => {
            this.setState({ showUserModal: false, selectedUser: null }, () => {
              this._refresh();
            });
          }}
          onHide={() =>
            this.setState({ showUserModal: false, selectedUser: null })
          }
        />
        <ConfirmModal
          show={this.state.showDeleteModal}
          title="Delete Investor"
          message="Are you sure you want to delete this investor?"
          onHide={() => this.setState({ showDeleteModal: false })}
          onConfirm={() => {
            this.setState({ showDeleteModal: false }, () => {
              this._deleteUser();
            });
          }}
        />
      </div>
    );
  }
}

UsersTable.defaultProps = {};
