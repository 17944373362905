import React from "react";

import BaseTable from "./BaseTable";

export default class EmailActivityTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh());
  }

  _refresh() {
    this.table.current.refresh();
  }

  _getAction(actions, selectedAction) {

    if (!actions || !selectedAction) {
      return <span className="text-dark ml-2">-</span>;
    }

    const actionIsInArray = actions.some((action) =>
      selectedAction.includes(action)
    );

    return (
      <>
        <span>
          {actionIsInArray && (
            <span
              className="fw-bold ml-2"
              style={{ color: "#35db6f", fontSize: 20 }}
            >
              ✓
            </span>
          )}
        </span>
      </>
    );
  }

  _getColumns() {
    return [
      {
        Header: "Email Activity",
        id: "user",
        Cell: (rowInfo) => {
          let emailData = rowInfo.original;

          let investorName = `${emailData.investor.user.first_name} ${emailData.investor.user.last_name}`;

          if (!investorName) {
            return "-";
          }

          return (
            <div>
              <span className="text-dark">{investorName}</span>
            </div>
          );
        },
      },
      {
        Header: "",
        id: "email",
        Cell: (rowInfo) => {
          let emailData = rowInfo.original;

          let investorEmail = emailData.investor.user.email;

          if (!investorEmail) {
            return "-";
          }

          return (
            <div>
              <span className="text-dark">{investorEmail}</span>
            </div>
          );
        },
      },
      {
        Header: "Delivered",
        id: "delivered",
        Cell: (rowInfo) => {
          let emailData = rowInfo.original;

          const actions = emailData.actions;
          const selectedAction = "delivered";

          return this._getAction(actions, selectedAction);
        },
        sortable: false,
        width: 120,
      },
      {
        Header: "Opened",
        id: "opened",
        Cell: (rowInfo) => {
          let emailData = rowInfo.original;

          const actions = emailData.actions;
          const selectedAction = "opened";

          return this._getAction(actions, selectedAction);
        },
        sortable: false,
        width: 120,
      },
      {
        Header: "Clicked",
        id: "click",
        Cell: (rowInfo) => {
          let emailData = rowInfo.original;

          const actions = emailData.actions;
          const selectedAction = "click";

          return this._getAction(actions, selectedAction);
        },
        sortable: false,
        width: 120,
      },

      {
        Header: "Unsubscribed",
        id: "unsubscribe",
        Cell: (rowInfo) => {
          let emailData = rowInfo.original;

          let actions = emailData.actions;
          const selectedAction = "unsubscribe";

          return this._getAction(actions, selectedAction);
        },
        sortable: false,
        width: 120,
      },
    ];
  }

  render() {
    let { email } = this.state;

    const columns = this._getColumns();

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.InvestorActivities}?mail_id=${email.id}&mail_only=true&paginate=true`}
          noDataMessage={"No data found"}
          title={this.props.title}
          columns={columns}
          showExport={false}
          showHeader={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          showSearch={false}
        />
      </div>
    );
  }
}

EmailActivityTable.defaultProps = {};
